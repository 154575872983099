import React from "react";
import "./styles.scss";

export default function SummaryTable({ data, quotationCodes }) {
  const quotationInfo = Object.keys(data).sort();
  console.log("summary", quotationCodes);
  return (
    <div className="summaryTable">
      <table>
        <thead>
          <tr>
            <th>Brand</th>
            <th>Item Name</th>
            {quotationCodes?.map((item) => {
              return <th>{item}</th>;
            })}
            <th>Sales total</th>
            {quotationCodes?.map((item) => {
              return <th>{item}</th>;
            })}
            <th>Purchase total</th>
            <th>P&L</th>
            <th>P&L(%)</th>
          </tr>
        </thead>
        <tbody>
          {quotationInfo?.map((key) => {
            const item = data[key];
            let lastBrand = "";

            let totalSalesAmount = 0;
            let totalPurchaseAmount = 0;
            const itemProductNames = Object.values(item?.productName);
            for (let index = 0; index < itemProductNames.length; index++) {
              const productName = itemProductNames[index];
              totalSalesAmount += quotationCodes?.reduce((a, b) => {
                return (
                  Number(a ?? 0) + Number(productName[b]?.totalSalesAmount ?? 0)
                );
              }, 0);
              totalPurchaseAmount += quotationCodes?.reduce(
                (a, b) =>
                  Number(a ?? 0) +
                  Number(productName[b]?.totalPurchaseAmount ?? 0),
                0
              );
            }
            return (
              <>
                {Object.values(item?.productName)?.map((productName) => {
                  const context = (
                    <tr>
                      <td>{lastBrand !== item?.brand ? item?.brand : ""}</td>
                      <td>{productName?.name}</td>
                      {quotationCodes?.map((item) => {
                        return (
                          <td>{productName[item]?.totalSalesAmount ?? 0}</td>
                        );
                      })}
                      <td>
                        {quotationCodes?.length !== 1
                          ? quotationCodes?.reduce((a, b) => {
                              console.log(
                                "productName a",
                                a,
                                b,
                                productName[b]?.totalSalesAmount
                              );
                              return (
                                Number(a ?? 0) +
                                Number(productName[b]?.totalSalesAmount ?? 0)
                              );
                            }, 0)
                          : productName[quotationCodes[0]]?.totalSalesAmount}
                      </td>
                      {quotationCodes?.map((item) => {
                        return (
                          <td>{productName[item]?.totalPurchaseAmount ?? 0}</td>
                        );
                      })}
                      <td>
                        {quotationCodes?.length !== 1
                          ? quotationCodes?.reduce(
                              (a, b) =>
                                Number(a ?? 0) +
                                Number(
                                  productName[b]?.totalPurchaseAmount ?? 0
                                ),
                              0
                            )
                          : productName[quotationCodes[0]]?.totalPurchaseAmount}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                  lastBrand = item?.brand;
                  return context;
                })}
                <tr>
                  <th>&nbsp;</th>
                  <th></th>
                  {quotationCodes?.map(() => (
                    <th></th>
                  ))}
                  <th style={{ color: "green" }}>{totalSalesAmount}</th>
                  {quotationCodes?.map(() => (
                    <th></th>
                  ))}
                  <th style={{ color: "green" }}>{totalPurchaseAmount}</th>
                  <th style={{ color: "green" }}>
                    {totalSalesAmount - totalPurchaseAmount}
                  </th>
                  <th style={{ color: "green" }}>
                    {!!totalSalesAmount
                      ? Number(
                          ((totalSalesAmount - totalPurchaseAmount) /
                            totalSalesAmount) *
                            100
                        ).toFixed(2)
                      : 0}
                    %
                  </th>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  {quotationCodes?.map(() => {
                    return <td></td>;
                  })}
                  <td></td>
                  {quotationCodes?.map(() => {
                    return <td></td>;
                  })}
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
