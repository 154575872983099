import React, { Component } from 'react';
import './fileupload.scss';
import Spin from '@gull/components/Spin';
import { connect } from 'react-redux';
import { storeFile } from "../../../redux/actions/FileStorageActions"
import { Button, ProgressBar } from 'react-bootstrap';
import { storageUrl } from 'environment/env';
import Image from '../Image';
import { NotificationManager } from 'react-notifications';
import { FILE_FORMATS } from '@utils';
import { Store } from 'app/redux/Store';
class FileUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fileName: '',
            isFileSet: false
        }
    }

    componentDidMount() {
        Store.dispatch({ type: "RESET_PROGRESS_BAR_VALUE" })
    }

    componentDidUpdate(prevProps) {
        if (this.props.fileUrl && (this.props.fileUrl !== prevProps.fileUrl)) {
            if (this.state.isFileSet) {
                NotificationManager.success('File uploaded successfully.')
                this.props.onUploadFinish(this.props.fileUrl, this.props.filepath)
            }
            this.setState({
                isFileSet: false
            })
        }
    }

    onChange = (e) => {
        let files = e.target.files || e.dataTransfer.files;

        if (files.length === 0) {
            NotificationManager.error('Please select a valid file.');
            return;
        }
        const filesList = Array.from(files);

        if (!this.props.isMultiple) {
            let ext = files[0].name.split('.').pop()
            if (this.props.allowedFiles) {
                if (!this.props.allowedFiles?.includes(`.${ext}`.toLowerCase())) {
                    NotificationManager.error(`Please select only ${this.props?.allowedFiles?.join(', ')} file`);
                    return;
                }
            }
            else {
                if (!FILE_FORMATS?.includes(`.${ext}`)) {
                    NotificationManager.error(`Please select only ${FILE_FORMATS?.join(', ')} file`);
                    return;
                }
            }

            if (this.props.fileSizeLimitInMb) {
                const size = files[0].size / 1048576;
                if (!(size <= this.props.fileSizeLimitInMb)) {
                    NotificationManager.error(`Please upload less or equal to ${this.props.fileSizeLimitInMb}`);
                    return;
                }
            }
        } else {
            let isValid = true
            filesList.forEach(file => {
                let ext = file.name.split('.').pop()
                if (this.props.allowedFiles) {
                    if (!this.props.allowedFiles?.includes(`.${ext}`.toLowerCase())) {
                        NotificationManager.error(`Please select only ${this.props?.allowedFiles?.join(', ')} file`);
                        isValid = false
                    }
                }
                else {
                    if (!FILE_FORMATS?.includes(`.${ext}`)) {
                        NotificationManager.error(`Please select only ${FILE_FORMATS?.join(', ')} file`);
                        isValid = false
                    }
                }

                if (this.props.fileSizeLimitInMb) {
                    const size = file.size / 1048576;
                    if (!(size <= this.props.fileSizeLimitInMb)) {
                        NotificationManager.error(`Please upload less or equal to ${this.props.fileSizeLimitInMb}`);
                        return;
                    }
                }
            })
            if (!isValid)
                return isValid;
        }


        this.setState({
            fileSet: files,
            files: filesList,
            isFileSet: true
        })
    }

    storeOnServer = () => {
        if (this.state.files.length === 0) {
            NotificationManager.error('Please select a valid file.');
            return false;
        }

        if (!this.props.isServerUpload) {
            this.props.onUploadFinish(this.state.fileSet, this.props.filepath)
        } else {
            const payload = new FormData();
            this.state.files.forEach(file => {
                payload.append('file[]', file);
            })
            payload.append('filepath', this.props.filepath);

            this.props.storeFile(payload);
        }
    }

    resetFile = () => {
        this.setState({
            files: [],
            fileName: '',
            isFileSet: true
        })
    }


    render() {
        return (
            <Spin loading={(this.props.loading)}>
                <div className="file-upload row" key={this.props.key}>
                    {/* <button className="file-upload-btn" type="button" onclick="$('.file-upload-input').trigger( 'click' )">Add Image</button> */}
                    <div className="image-upload-wrap mb-2 w-100">
                        <input className="file-upload-input" accept={!!this.props.allowedFiles ? this.props.allowedFiles.join(', ') : ''} multiple={!!this.props.isMultiple ? this.props.isMultiple : false} disabled={(this.props.fileUrl && (this.state.file))} type="file" onChange={this.onChange} />
                        <div className="drag-text">
                            {
                                (this.props.initalData && !this.state.isFileSet)
                                    ? this.props.initalData.includes('.pdf') ? <Image width="100px" src={`${process.env.PUBLIC_URL}/assets/images/pdf-file.png`} alt="N S Solution logo" /> : <Image width={150} src={`${storageUrl}${this.props.initalData}`} alt={this.props.filepath} />
                                    : <h4>Drag and drop a file or click to add File</h4>
                            }
                            <ProgressBar now={this.props.progressBarValue} label={`${this.props.progressBarValue}%`} />
                        </div>

                    </div>
                    {
                        (this.state.files.length !== 0) && <div className="col-md-12 file-upload-content mb-2">
                            {
                                this.state.files.map((file, i) => (
                                    <label className="file-name" key={i}>
                                        <svg className="mr-1" viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 0 0 174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>
                                        {file.name}
                                    </label>
                                ))
                            }
                            {/* <label className="remove-btn" onClick={e => this.setState({ fileName: '', image: '' })}> <i className="fas fa-trash" /> </label> */}
                        </div>
                    }
                    <Button onClick={e => this.storeOnServer()} > Upload File </Button>
                    <Button onClick={e => this.resetFile()} variant={`outline-primary`} className="ml-1" > Clear </Button>

                </div>
            </Spin>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    loading: state.fileStorage.loading,
    fileUrl: state.fileStorage.url,
    progressBarValue: state?.misc?.progressBarValue || 0
})

const mapDispatchToProps = {
    storeFile
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);