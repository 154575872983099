/* eslint-disable */
import { NotificationManager } from "react-notifications";
import localStorageService from "./localStorageService";
import { apiUrl } from "../../environment/env";
import { onUploadProgress } from "@utils";

const axios = require("axios");
let singletonAxios;
const HEADER_TIMEOUT_MS = 120000;

const HTTP_HEADERS = getHttpHeader();

function getHttpHeader() {
  return {
    "Content-Type": "application/json",
    token: localStorage.getItem("jwt_token"),
    Authorization: "Bearer " + localStorage.getItem("jwt_token"),
  };
}

export function apiAdapter(options = {}) {
  const axios = getAxiosInstance(options);

  axios.interceptors.response.use(
    (response) => handleAxiosResponseSuccess(response),
    (error) => handleAxiosResponseError(error)
  );

  return axios(options);
}

function handleAxiosResponseSuccess(response) {
  switch (response?.data?.status) {
    case 404:
      NotificationManager.error(response.data?.error, "Error");
      break;
    case 403:
      NotificationManager.error(response.data?.error, "Error");
      break;
    case 401:
      NotificationManager.error(response.data?.error, "Error");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
      break;
    case 500:
    case 503:
      NotificationManager.error(response.data?.error, "Error");
      break;
    case 200:
    case 201:
    case 202:
      return response;
    default:
      return response;
  }
  // return response;
}

function handleAxiosResponseError(error) {
  switch (error.response?.data?.status) {
    case 404:
      NotificationManager.error(error.response.data?.error, "Error");
      break;
    case 403:
      NotificationManager.error(error.response.data?.error, "Error");
      // localStorage.clear()
      // setTimeout(() => {
      //     window.location.href = '/login'
      // }, 1000);
      break;
    case 401:
      NotificationManager.error(error.response.data?.error, "Error");
      localStorage.clear()
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
      break;
    case 500:
    case 503:
      NotificationManager.error(error.response.data?.error, "Error");
      break;
    case 200:
    case 201:
    case 202:
      return error;
    default:
      return error;
  }
}

function getAxiosInstance(options) {
  if (options.overrideHeader) {
    singletonAxios = axios.create({
      baseURL: getBaseURL(options),
      headers: { ...getHttpHeader(), ...options.overrideHeader },
      onUploadProgress: onUploadProgress,
    });
  } else {
    singletonAxios = axios.create({
      baseURL: getBaseURL(options),
      headers: getHttpHeader(),
      onUploadProgress: onUploadProgress,
    });
  }
  singletonAxios.defaults.timeout = HEADER_TIMEOUT_MS;
  return singletonAxios;
}

function getBaseURL(options) {
  return apiUrl;
}

export function getPostFilterPayload() {
  return {
    filters: {
      pageNo: 0,
      pageSize: 10,
      totalCount: 0,
    },
  };
}
