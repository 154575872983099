import {
  getFeaturedProducts,
  getRecommendedProducts,
  getNewProducts,
  createNewEnquiry,
  createNewPlaceOrder,
  getProductsById,
  getLayouts,
  createLayout,
  updateLayout,
  updatePlaceOrder,
  exportProduct,
  deleteLayout,
  getProductsWithFiltersV2,
  getProductBrandCodeList,
  getSearchedProductsV2,
  createProductV2,
  updateProductV2,
  deleteProductsV2,
} from "../../services/ProductService";
import { NotificationManager } from "react-notifications";

export const GET_FEATURED_DIAMOND_BEGIN = "GET_FEATURED_DIAMOND_BEGIN";
export const GET_FEATURED_DIAMOND_SUCESS = "GET_FEATURED_DIAMOND_SUCESS";
export const GET_FEATURED_DIAMOND_FAILED = "GET_FEATURED_DIAMOND_FAILED";

export const GET_DIAMOND_BY_ID_BEGIN = "GET_DIAMOND_BY_ID_BEGIN";
export const GET_DIAMOND_BY_ID_SUCESS = "GET_DIAMOND_BY_ID_SUCESS";
export const GET_DIAMOND_BY_ID_FAILED = "GET_DIAMOND_BY_ID_FAILED";

export const GET_NEW_DIAMOND_BEGIN = "GET_NEW_DIAMOND_BEGIN";
export const GET_NEW_DIAMOND_SUCESS = "GET_NEW_DIAMOND_SUCESS";
export const GET_NEW_DIAMOND_FAILED = "GET_NEW_DIAMOND_FAILED";

export const CREATE_ENQUIRY_BEGIN = "CREATE_ENQUIRY_BEGIN";
export const CREATE_ENQUIRY_SUCESS = "CREATE_ENQUIRY_SUCESS";
export const CREATE_ENQUIRY_FAILED = "CREATE_ENQUIRY_FAILED";

export const GET_LAYOUTS_BEGIN = "GET_LAYOUTS_BEGIN";
export const GET_LAYOUTS_SUCESS = "GET_LAYOUTS_SUCESS";
export const GET_LAYOUTS_FAILED = "GET_LAYOUTS_FAILED";

export const CREATE_LAYOUT_BEGIN = "CREATE_LAYOUT_BEGIN";
export const CREATE_LAYOUT_SUCESS = "CREATE_LAYOUT_SUCESS";
export const CREATE_LAYOUT_FAILED = "CREATE_LAYOUT_FAILED";

export const CREATE_DIAMOND_BEGIN = "CREATE_DIAMOND_BEGIN";
export const CREATE_DIAMOND_SUCESS = "CREATE_DIAMOND_SUCESS";
export const CREATE_DIAMOND_FAILED = "CREATE_DIAMOND_FAILED";

export const fetchFeaturedProduct = (type) => (dispatch) => {
  dispatch({
    type: GET_FEATURED_DIAMOND_BEGIN,
  });
  getFeaturedProducts()
    .then((resp) => {
      if (resp?.data?.data) {
        dispatch({
          type: GET_FEATURED_DIAMOND_SUCESS,
          payload: resp?.data?.data,
        });
      } else {
        dispatch({
          type: GET_FEATURED_DIAMOND_SUCESS,
          payload: { data: [] },
        });
      }
    })
    .catch((e) => {
      NotificationManager.error("Fail to update record, Please try again.");
      dispatch({
        type: GET_FEATURED_DIAMOND_SUCESS,
        payload: { data: [] },
      });
    });
};

export const createNewProduct = (payload) => (dispatch) => {
  return createProductV2(payload)
    .then((resp) => {
      if (resp?.data?.data) {
        NotificationManager.success("Record updated successfully");
        return resp?.data?.data;
      } else {
        NotificationManager.error("Fail to update record, Please try again.");
      }
    })
    .catch((e) => {
      NotificationManager.error("Fail to update record, Please try again.");
    });
};

export const updateNewProduct = (payload) => (dispatch) => {
  return updateProductV2(payload)
    .then((resp) => {
      if (resp?.data?.data) {
        NotificationManager.success("Record updated successfully");
        return resp?.data?.data;
      } else {
        console.log(resp?.data?.error);
        NotificationManager.error("Fail to update record, Please try again.");
        return null;
      }
    })
    .catch((e) => {
      NotificationManager.error("Fail to update record, Please try again.");
    });
};

export const deleteProduct = (payload) => (dispatch) => {
  return deleteProductsV2(payload)
    .then((resp) => {
      if (resp?.data?.data) {
        NotificationManager.success(
          `${resp?.data?.data} Record deleted successfully`
        );
        return resp?.data?.data;
      } else {
        console.log(resp?.data?.error);
        NotificationManager.error(
          `Fail to delete record. ${resp?.data?.error || ""}`
        );
        return null;
      }
    })
    .catch((e) => {
      NotificationManager.error("Fail to delete record, Please try again.");
    });
};

export const deleteLayouts = (payload) => (dispatch) => {
  return deleteLayout(payload)
    .then((resp) => {
      if (resp?.data?.success) {
        NotificationManager.success(
          `${resp?.data?.data} Record deleted successfully`
        );
        return resp?.data;
      } else {
        console.log(resp?.data?.error);
        NotificationManager.error(
          `Fail to delete record. ${resp?.data?.error || ""}`
        );
        return null;
      }
    })
    .catch((e) => {
      NotificationManager.error("Fail to delete record, Please try again.");
    });
};

export const createNewLayout = (payload) => (dispatch) => {
  return createLayout(payload)
    .then((resp) => {
      if (resp?.data) {
        NotificationManager.success("Record updated successfully");
        return resp?.data;
      } else {
        NotificationManager.error("Fail to update record, Please try again.");
      }
    })
    .catch((e) => {
      NotificationManager.error("Fail to update record, Please try again.");
    });
};

export const updateNewLayout = (payload) => (dispatch) => {
  return updateLayout(payload)
    .then((resp) => {
      if (resp?.data) {
        NotificationManager.success("Record updated successfully");
        return resp?.data;
      } else {
        console.log(resp?.data?.error);
        NotificationManager.error("Fail to update record, Please try again.");
      }
    })
    .catch((e) => {
      NotificationManager.error("Fail to update record, Please try again.");
    });
};

export const fetchProductById = (id) => (dispatch) => {
  dispatch({
    type: GET_DIAMOND_BY_ID_BEGIN,
  });
  getProductsById(id)
    .then((resp) => {
      if (resp?.data?.data?.data?.length) {
        dispatch({
          type: GET_DIAMOND_BY_ID_SUCESS,
          payload: resp?.data?.data?.data,
        });
      } else {
        dispatch({
          type: GET_DIAMOND_BY_ID_FAILED,
          payload: [],
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: GET_DIAMOND_BY_ID_FAILED,
        payload: [],
      });
    });
};

export const fetchProductWithFiltersV2 = (filters) => (dispatch) => {
  dispatch({
    type: GET_DIAMOND_BY_ID_BEGIN,
  });
  getProductsWithFiltersV2(filters)
    .then((resp) => {
      if (resp?.data?.data?.data?.length) {
        dispatch({
          type: GET_DIAMOND_BY_ID_SUCESS,
          payload: resp?.data?.data?.data,
        });
      } else {
        dispatch({
          type: GET_DIAMOND_BY_ID_FAILED,
          payload: [],
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: GET_DIAMOND_BY_ID_FAILED,
        payload: [],
      });
    });
};

export const fetchNewProduct = (type) => (dispatch) => {
  dispatch({
    type: GET_NEW_DIAMOND_BEGIN,
  });
  getNewProducts().then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_FEATURED_DIAMOND_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: GET_FEATURED_DIAMOND_SUCESS,
        payload: { data: [] },
      });
    }
  });
};

export const fetchSearchedProduct =
  (page = 1, payload = {}) =>
  (dispatch) => {
    dispatch({
      type: GET_FEATURED_DIAMOND_BEGIN,
    });
    getSearchedProductsV2(page, payload).then((resp) => {
      if (resp?.data?.data) {
        dispatch({
          type: GET_FEATURED_DIAMOND_SUCESS,
          payload: resp?.data?.data,
        });
      } else {
        dispatch({
          type: GET_FEATURED_DIAMOND_SUCESS,
          payload: [],
        });
      }
    });
  };

export const fetchRecommendedProduct = (type) => (dispatch) => {
  dispatch({
    type: GET_FEATURED_DIAMOND_BEGIN,
  });
  getRecommendedProducts().then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_FEATURED_DIAMOND_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: GET_FEATURED_DIAMOND_SUCESS,
        payload: [],
      });
    }
  });
};

export const createProductEnquiry = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_ENQUIRY_BEGIN,
  });
  createNewEnquiry(payload).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: CREATE_ENQUIRY_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: CREATE_ENQUIRY_FAILED,
        payload: [],
      });
    }
  });
};

export const createProductPlaceOrder = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_ENQUIRY_BEGIN,
  });
  createNewPlaceOrder(payload).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: CREATE_ENQUIRY_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: CREATE_ENQUIRY_SUCESS,
        payload: [],
      });
    }
  });
};

export const updateProductPlaceOrder = (payload) => () => {
  return updatePlaceOrder(payload).then((resp) => {
    return resp;
  });
};

export const fetchLayouts =
  (page = 1, id) =>
  (dispatch) => {
    dispatch({
      type: GET_LAYOUTS_BEGIN,
    });
    getLayouts(page, id).then((resp) => {
      if (resp?.data?.data) {
        dispatch({
          type: GET_LAYOUTS_SUCESS,
          payload: resp?.data?.data,
        });
      } else {
        dispatch({
          type: GET_LAYOUTS_SUCESS,
          payload: [],
        });
      }
    });
  };

export const exportProducts = (payload) => () => {
  return exportProduct(payload).then((resp) => {
    return resp;
  });
};

export const fetchProductBrandCodeList = (filters) => () => {
  return getProductBrandCodeList(filters).then((resp) => {
    return resp;
  });
};
