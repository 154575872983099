import React from "react";
import { envType } from "environment/env";
import { Redirect } from "react-router-dom";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Profile from "./views/pages/profile/Profile";
import Login from "./views/pages/login/login";
import AdminProducts from "./views/pages/admin-products/AdminProduct";
import ProductForm from "./views/pages/admin-products/ProductForm";
import AdminUsers from "./views/pages/admin-users/AdminUsers";
import UserForm from "./views/pages/admin-users/UserForm";
import UserDetail from "./views/pages/admin-users/UserDetail";
import AdminEnquiries from "./views/pages/admin-enquiries/AdminEnquiries";
import Dashboard from "./views/dashboard/dashboard/Dashboard";
import AdminUploadVideo from "./views/pages/admin-upload-videos/AdminUploadVideo";
import SlideShowSetting from "./views/pages/slide-show";
import AdminOrders from "./views/pages/admin-orders/AdminOrders";
import AdminQuatations from "./views/pages/admin-quotations/AdminQuotations";
import QuotationDetails from "./views/pages/admin-quotations/QuotationDetails";
import QuotationForms from "./views/pages/admin-quotations/QuotationForms";
import AdminCustomer from "./views/pages/admin-customer/AdminCustomer";
import QuotationReport from "./views/pages/admin-quotations/QuotationReport";
import LoginStaging from "./views/pages/login/login-staging";
import AdminQuotationSummary from "./views/pages/admin-quotation-summary";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/404" />,
  },
];

const publicRoutes = [];

const adminRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    auth: authRoles.admin,
  },
  {
    path: "/enquiries",
    component: AdminEnquiries,
    auth: authRoles.admin,
  },
  {
    path: "/orders",
    component: AdminOrders,
    auth: authRoles.admin,
  },
  {
    path: "/product/form/:productid?",
    component: ProductForm,
    auth: authRoles.admin,
  },
  {
    path: "/product",
    component: AdminProducts,
    auth: authRoles.admin,
  },
  {
    path: "/files",
    component: AdminUploadVideo,
    auth: authRoles.admin,
  },
  {
    path: "/users",
    component: AdminUsers,
    auth: authRoles.admin,
  },
  {
    path: "/user/form/:userid?",
    component: UserForm,
    auth: authRoles.admin,
  },
  {
    path: "/user/:userid",
    component: UserDetail,
    auth: authRoles.admin,
  },
  {
    path: "/profile",
    component: Profile,
    auth: authRoles.admin,
  },
  {
    path: "/slide-show",
    component: SlideShowSetting,
    auth: authRoles.admin,
  },
  {
    path: "/quotations",
    component: AdminQuatations,
    auth: authRoles.admin,
  },
  {
    path: "/quotation/report",
    component: QuotationReport,
    auth: authRoles.admin,
  },
  {
    path: "/quotation/summary",
    component: AdminQuotationSummary,
    auth: authRoles.admin,
  },
  {
    path: "/quotation/form/:quotationId?",
    component: QuotationForms,
    auth: authRoles.admin,
  },
  {
    path: "/quotation/:quotationId?",
    component: QuotationDetails,
    auth: authRoles.admin,
  },
  {
    path: "/customer",
    component: AdminCustomer,
    auth: authRoles.admin,
  },
];

const routes = [
  ...sessionsRoutes,
  ...publicRoutes,
  {
    path: "/login",
    component: envType === "staging" ? LoginStaging : Login,
  },
  {
    path: "/",
    component: AuthGuard,
    routes: [...adminRoutes, ...redirectRoute, ...errorRoute],
  },
];

export default routes;
