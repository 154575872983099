import React, { Component } from "react";
import { connect } from "react-redux";
import "./AdminUsersStyle.scss";
import { Breadcrumb, SimpleCard } from "@gull";
import { Button } from "react-bootstrap";
import {
  fetchUsers,
  createNewUser,
  updateNewUser,
} from "../../../redux/actions/UserActions";
import Spin from "@gull/components/Spin";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

const UserFormSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
  mobile_no: yup.string().required("Mobile No is required"),
});

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: this.props?.match?.params?.userid,
      formLoading: false,
      formData: {
        name: "",
        email: "",
        mobile_no: "",
      },
      loading: false,
    };
  }

  componentDidMount() {
    // this.props.fetchCountries({})
    if (this.state.userid) {
      this.props.fetchUsers({ id: this.state.userid });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userData !== prevProps.userData) {
      const { userData } = this.props;
      let user = {};
      if (userData?.length) {
        user = userData[0];
        this.setState({
          formData: {
            ...this.state.formData,
            name: user.name ? user.name : "",
            email: user.email ? user.email : "",
            mobile_no: user.mobile_no ? user.mobile_no : "",
            whatsapp_no: user.whatsapp_no ? user.whatsapp_no : "",
            profile_img: user.profile_img ? user.profile_img : "",
          },
        });
      }
    }
  }
  handleSubmit = (value, { isSubmitting }) => {
    const that = this;
    this.setState(
      {
        formLoading: true,
      },
      () => {
        const payload = {
          ...that.state.formData,
          ...value,
        };

        if (that.state.userid) {
          payload["id"] = atob(that.state.userid);
          payload["updated_at"] = moment();
        } else {
          const phoneCode = that.props.countryList.find(
            (item) =>
              Number(item.id) === Number(that.state.formData.company_country_id)
          )?.phonecode;
          const whatsapp_no = `+${phoneCode}${value.whatsapp_no}`;
          const mobile_no = `+${phoneCode}${value.mobile_no}`;
          payload["whatsapp_no"] = whatsapp_no;
          payload["mobile_no"] = mobile_no;
        }

        const resp = that.state.userid
          ? that.props.updateNewUser
          : that.props.createNewUser;
        resp(payload)
          .then((resp) => {
            if (resp) {
              that.setState(
                {
                  formLoading: false,
                },
                () => {
                  that.props.history.push("/users");
                }
              );
            } else {
              that.setState({
                formLoading: false,
              });
            }
          })
          .catch((e) => {
            console.error(e);
            that.setState({
              formLoading: false,
            });
          });
      }
    );
  };

  handleFormValueChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };
  render() {
    const { loading } = this.props;
    const { formLoading, userid } = this.state;
    return (
      <div className="diamond-form">
        <Breadcrumb
          routeSegments={[
            { name: `${userid ? "Edit" : "Add"} User`, path: "/" },
          ]}
          isBackButton={true}
        ></Breadcrumb>
        <Spin loading={loading | formLoading}>
          <Formik
            enableReinitialize
            initialValues={this.state.formData}
            validationSchema={UserFormSchema}
            onSubmit={this.handleSubmit}
            handleChange={this.handleFormValueChange}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <form
                  onSubmit={(e) => {
                    console.log(errors);
                    handleSubmit(e);
                  }}
                >
                  <div className="row mb-5">
                    <div className="col-md-6">
                      <SimpleCard title="Personal Info" className="mb-3">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-6">
                                <b>Full Name</b>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="name"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    placeholder="Name"
                                  />
                                  {errors.name && touched.name && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <b>Email</b>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="email"
                                    name="email"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder="Email"
                                  />
                                  {errors.email && touched.email && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.email}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <b>Password</b>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="password"
                                    name="password"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    placeholder="Password"
                                  />
                                  {errors.password && touched.password && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.password}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <b>Mobile No</b>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="mobile_no"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.mobile_no}
                                    placeholder="Mobile No"
                                  />
                                  {errors.mobile_no && touched.mobile_no && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.mobile_no}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Button
                          key={"primary3"}
                          variant={`primary`}
                          className="btn-rounded m-1 text-capitalize"
                          style={{ float: "right" }}
                          type="submit"
                        >
                          Update User
                        </Button>
                      </SimpleCard>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.userList?.data,
  loading: state.user.loading,
  user: state.user,
});

const mapDispatchToProps = {
  fetchUsers,
  createNewUser,
  updateNewUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
