import React, { Component } from 'react'
import './styles.scss';
import { Breadcrumb } from '@gull';
import { Button, Tab, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateNewUser } from '../../../redux/actions/UserActions';
import { fetchCountries, fetchStates, fetchCities } from '../../../redux/actions/MiscActions';
import Spin from '@gull/components/Spin';
import { NotificationManager } from "react-notifications";
import Image from 'app/GullLayout/SharedComponents/Image';
import {storageUrl} from '../../../../environment/env';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            formLoading: false
        }
        // this.props.fetchCountries({})
        // this.props.fetchStates({ countryId: props.user.company_country.id })
        // this.props.fetchCities({ stateId: props.user.company_state.id })
    }
    handleInputChange = e => {
        const name = e.target.name
        const addon = {};
        switch (name) {
            case 'company_country_id':
                addon.company_country = { id: e.target.value };
                break;
            case 'company_state_id':
                addon.company_state = { id: e.target.value };
                break;
            case 'company_city_id':
                addon.company_city = { id: e.target.value };
                break;
            default: break;
        }
        this.setState({
            user: {
                ...this.state.user,
                [name]: e.target.value,
                ...addon
            }
        }, () => {
            // if (name === 'company_country_id') {
            //     this.props.fetchStates({ countryId: e.target.value })
            // }
            // if (name === 'company_state_id') {
            //     this.props.fetchCities({ stateId: e.target.value })
            // }
        })
    }
    onUploadFinish = (url, type) => {
        let key = "profile_img";
        switch (type) {
            case "profile": key = "profile_img"; break;
            case "company": key = "company_profile_img"; break;
            default: key = "profile_img";
        }
        this.setState({
            user: {
                ...this.state.user,
                [key]: url,
            }
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const that = this
        this.setState({
            formLoading: true
        }, () => {
            const payload = {
                ...that.state.user,
            }

            that.props.updateNewUser(payload).then(resp => {
                if (resp) {
                    NotificationManager.success('Profile Successfully updated. Please Re-login to apply changes', 'Success')
                    that.setState({
                        formLoading: false
                    })
                } else {
                    that.setState({
                        formLoading: false
                    })
                }
            })
                .catch(e => {
                    console.error(e);
                    that.setState({
                        formLoading: false
                    })
                });
        })
    };
    render() {
        const { user, formLoading } = this.state;
        return (
            <div>
                <Breadcrumb
                    routeSegments={[
                        { name: 'Profile', path: "/profile" }
                    ]}
                ></Breadcrumb>
                <Spin loading={formLoading}>
                    <div className="profile row">
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-center">
                                        {/* <FileUpload initalData={user.sprofile_img} isServerUpload={true} onUploadFinish={this.onUploadFinish} filepath="profile" /> */}
                                        <Image src={user.profile_img ? `${storageUrl}${user.profile_img}` : `${process.env.PUBLIC_URL}/assets/images/faces/face-1.png`} roundedCircle />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills" className="d-flex  px-2">
                                    <Nav.Item className="mr-2 flex-grow-1 text-center">
                                        <Nav.Link eventKey="first">Personal Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="mr-2 flex-grow-1 text-center">
                                        <Nav.Link eventKey="three">Change Password</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Name*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter Name"
                                                        value={user.name}
                                                        name="name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Email ID*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="email"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter Email"
                                                        value={user.email}
                                                        name="email"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Mobile No*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter Mobile Number"
                                                        value={user.mobile_no}
                                                        name="mobile_no"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="col-md-3">
                                                    <Button
                                                        block
                                                        key={'primary'}
                                                        variant={`outline-primary`}
                                                        className="btn-rounded m-1 text-capitalize"
                                                        style={{ float: 'right' }}
                                                        onClick={this.handleSubmit}
                                                    >
                                                        Save Changes
                                                </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Company Name*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter Company Name"
                                                        value={user.company_name}
                                                        name="company_name"
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                            <label
                                                htmlFor="inputEmail3"
                                                className="col-sm-12 col-form-label"
                                            >
                                                Business Type*
                    </label>
                                            <div className="col-sm-12">
                                                <select className="form-control">
                                                    <option>Option 1</option>
                                                    <option>Option 1</option>
                                                    <option>Option 1</option>
                                                </select>
                                            </div>
                                        </div> */}
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Address Line 1*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter Address Line 1"
                                                        value={user.company_address1}
                                                        name="company_address1"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Address Line 2*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter Address Line 2"
                                                        value={user.company_address2}
                                                        name="company_address2"
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Country*
                    </label>
                                                <div className="col-sm-12">
                                                    <select onChange={this.handleInputChange} name="company_country_id" className="form-control" value={user.company_country.id}>
                                                        <option>{countryloading ? 'Loading..' : 'Select Country'}</option>
                                                        {
                                                            countryList && countryList.map(country => (
                                                                <option value={country.id}>{country.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    State*
                    </label>
                                                <div className="col-sm-12">
                                                    <select onChange={this.handleInputChange} name="company_state_id" className="form-control" value={user.company_state.id}>
                                                        <option>{stateloading ? 'Loading..' : 'Select State'}</option>
                                                        {
                                                            stateList && stateList.map(state => (
                                                                <option value={state.id}>{state.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    City*
                    </label>
                                                <div className="col-sm-12">
                                                    <select onChange={this.handleInputChange} className="form-control" value={user.company_city.id} name="company_city_id">
                                                        <option>{cityloading ? 'Loading..' : 'Select City'}</option>
                                                        {
                                                            cityList && cityList.map(city => (
                                                                <option value={city.id}>{city.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Zip Code*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter Zipcode"
                                                        value={user.company_zipcode}
                                                        name="company_zipcode"
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-md-12 mt-3">
                                                <div className="col-md-3">
                                                    <Button
                                                        block
                                                        key={'primary'}
                                                        variant={`outline-primary`}
                                                        className="btn-rounded m-1 text-capitalize"
                                                        style={{ float: 'right' }}
                                                        onClick={this.handleSubmit}
                                                    >
                                                        Save Changes
                                                </Button>
                                                </div>
                                            </div>
                                        </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="three">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Current Password*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter Current Password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    New Password*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter New Password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="inputEmail3"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    Confirm Password*
                    </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        id="inputEmail3"
                                                        placeholder="Enter Confirm Password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="col-sm-3">
                                                    <Button
                                                        block
                                                        key={'primary'}
                                                        variant={`outline-primary`}
                                                        className="btn-rounded m-1 text-capitalize"
                                                        style={{ float: 'right' }}
                                                    >
                                                        Change Password
                                                </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>


                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    user: state.user,
    settings: state.layout.settings,
    countryloading: state.misc?.countryloading,
    countryList: state.misc?.countryList,
    stateloading: state.misc?.stateloading,
    stateList: state.misc?.stateList,
    cityloading: state.misc?.cityloading,
    cityList: state.misc?.cityList,
});
const mapDispatchToProps = {
    updateNewUser,
    fetchCountries,
    fetchStates,
    fetchCities
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);