import {
  GET_CUSTOMER_BEGIN,
  GET_CUSTOMER_SUCESS,
  GET_CUSTOMER_FAILED,
  CREATE_CUSTOMER_BEGIN,
  CREATE_CUSTOMER_SUCESS,
  CREATE_CUSTOMER_FAILED,
} from "../actions/CustomerActions";

const initialState = {
  loading: false,
  data: [],
};

const CustomerReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CUSTOMER_SUCESS: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    }
    case GET_CUSTOMER_FAILED: {
      return {
        ...state,
        loading: false,
        data: [],
        customerError: action.payload,
      };
    }
    case CREATE_CUSTOMER_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_CUSTOMER_SUCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CREATE_CUSTOMER_FAILED: {
      return {
        ...state,
        loading: false,
        customerError: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default CustomerReducer;
