import Image from "app/GullLayout/SharedComponents/Image";
import { storageUrl } from "environment/env";
import React from "react";

function QuotationProductItemRow({
  removeProductItem,
  handleProjectItemCalculations,
  columns,
  data,
  index,
  mainProductIndex,
  normalQuotationProductLength,
  readOnly,
  disabled,
}) {
  const style = {
    width: "auto",
    whiteSpace: "pre-wrap",
  };

  const is_manifold = data.is_manifold;
  return (
    <tr className={disabled ? "disabled" : ""}>
      {columns.map((col, i) => {
        let colStyle = {
          ...style,
          borderTop: "none",
        };
        if (col.width) {
          colStyle = {
            ...colStyle,
            width: col.width,
          };
        }

        if (readOnly) {
          console.log(data);
          switch (col.dataIndex) {
            case "image_url":
              return (
                <td key={i} style={colStyle} className={col.className}>
                  {is_manifold ? (
                    <>
                      <Image
                        src={encodeURI(
                          `${storageUrl}${data?.product_item[col.dataIndex]}`
                        )}
                        class="img-fluid"
                        style={{
                          width: 200,
                        }}
                        alt={data?.product?.name}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </td>
              );
            case "description":
              return (
                <td key={i} style={colStyle} className={col.className}>
                  {is_manifold ? (
                    <>
                      {col.render
                        ? col.render(data[col.dataIndex], data)
                        : data?.product[col.dataIndex]
                        ? data?.product[col.dataIndex]
                        : "-"}
                    </>
                  ) : (
                    <>{data?.product_item?.size} mm</>
                  )}
                </td>
              );
            case "quantity":
            case "sales_discount":
            case "purchase_discount":
            case "price":
            case "sales_net_amount":
            case "sales_total_amount":
            case "purchase_net_amount":
            case "purchase_total_amount":
              return (
                <td key={i} style={colStyle} className={col.className}>
                  <div className="form-group">
                    <div
                      className="form-control position-relative"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      {data[col.dataIndex]}
                    </div>
                  </div>
                </td>
              );
            case "id":
              return (
                <td key={i} style={colStyle} className={col.className}>
                  <div className="form-group">
                    <div
                      className="form-control position-relative"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      {mainProductIndex +
                        (normalQuotationProductLength ?? 0) +
                        1}
                      .{index + 1}
                    </div>
                  </div>
                </td>
              );
            default:
              return (
                <td key={i} style={colStyle} className={col.className}>
                  {col.render
                    ? col.render(data[col.dataIndex], data)
                    : data?.product_item[col.dataIndex]
                    ? data?.product_item[col.dataIndex]
                    : "-"}
                </td>
              );
          }
        }

        switch (col.dataIndex) {
          case "id":
            return (
              <td
                key={i}
                style={{
                  ...colStyle,
                }}
                className={col.className}
              >
                <div
                  style={{
                    alignItems: "center",
                    justifyItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    removeProductItem(data, index, mainProductIndex);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="red"
                      d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5zm-6 10v2h12v-2H10z"
                    />
                  </svg>
                </div>
              </td>
            );
          case "image_url":
            return (
              <td key={i} style={colStyle} className={col.className}>
                {is_manifold ? (
                  <>
                    <Image
                      src={encodeURI(
                        `${storageUrl}${data?.product_item?.[col.dataIndex]}`
                      )}
                      class="img-fluid"
                      style={{
                        width: 200,
                      }}
                      alt={data?.product?.name}
                    />
                  </>
                ) : (
                  ""
                )}
              </td>
            );
          case "description":
            return (
              <td key={i} style={colStyle} className={col.className}>
                {is_manifold ? (
                  <>
                    {col.render
                      ? col.render(data[col.dataIndex], data)
                      : data?.product[col.dataIndex]
                      ? data?.product[col.dataIndex]
                      : "-"}
                  </>
                ) : (
                  <>{data?.product_item?.size} mm</>
                )}
              </td>
            );
          case "quantity":
          case "sales_discount":
          case "purchase_discount":
            return (
              <td key={i} style={colStyle} className={col.className}>
                <div className="form-group">
                  <input
                    className="form-control position-relative"
                    type="text"
                    name={col.dataIndex}
                    onChange={(e) => {
                      handleProjectItemCalculations(
                        data?.is_manifold,
                        mainProductIndex,
                        index,
                        data?.product_item?.code,
                        e.target?.name,
                        e.target?.value ?? 0
                      );
                    }}
                    value={data[col.dataIndex]}
                    placeholder={col.dataIndex}
                  />
                </div>
              </td>
            );
          case "price":
          case "sales_net_amount":
          case "sales_total_amount":
          case "purchase_net_amount":
          case "purchase_total_amount":
            return (
              <td key={i} style={colStyle} className={col.className}>
                <div className="form-group">
                  <div
                    className="form-control position-relative"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  >
                    {data[col.dataIndex]}
                  </div>
                </div>
              </td>
            );
          default:
            return (
              <td key={i} style={colStyle} className={col.className}>
                {col.render
                  ? col.render(data[col.dataIndex], data)
                  : data?.product_item[col.dataIndex]
                  ? data?.product_item[col.dataIndex]
                  : "-"}
              </td>
            );
        }
      })}
    </tr>
  );
}

export default React.memo(QuotationProductItemRow);
