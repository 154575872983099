const { apiAdapter } = require("./ApiAdapter")

const StoreFile = (data) => {
    return apiAdapter({
        url: 'file/store',
        method: 'POST',
        data,
        overrideHeader: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

const videoStory = (params) => {
    return apiAdapter({
        url: 'file/get/all',
        method: 'POST',
        data:params,
    })
}

const deleteVideoStory = (params) => {
    return apiAdapter({
        url: 'file/videos/delete',
        method: 'GET',
        params,
    })
}

module.exports = {
    StoreFile,
    videoStory,
    deleteVideoStory
}