const { apiAdapter } = require("./ApiAdapter");

const GetQuotations = (params) => {
  return apiAdapter({
    url: "quotation",
    method: "GET",
    params,
  });
};
const GenereateId = (params) => {
  return apiAdapter({
    url: "quotation/id/generate?",
    method: "GET",
    params,
  });
};

const CreateQuotation = (data) => {
  return apiAdapter({
    url: "quotation/create",
    method: "POST",
    data,
  });
};
const UpdateQuotation = (data) => {
  return apiAdapter({
    url: "quotation/update",
    method: "POST",
    data,
  });
};
const DeleteQuotation = (data) => {
  return apiAdapter({
    url: "quotation/delete",
    method: "DELETE",
    data,
  });
};
const ExportQuotation = (data) => {
  return apiAdapter({
    url: "quotation/export/excel",
    method: "POST",
    data,
  });
};
const PdfExportQuotation = (data) => {
  return apiAdapter({
    url: "quotation/export/pdf",
    method: "POST",
    data,
  });
};
const SoftDeleteQuotation = (data) => {
  return apiAdapter({
    url: "quotation/soft-delete",
    method: "POST",
    data,
  });
};
const GetQuotationTerms = (params) => {
  return apiAdapter({
    url: "quotation/terms",
    method: "GET",
    params,
  });
};
const CreateQuotationTerms = (data) => {
  return apiAdapter({
    url: "quotation/terms/create",
    method: "POST",
    data,
  });
};

module.exports = {
  GetQuotations,
  CreateQuotation,
  UpdateQuotation,
  SoftDeleteQuotation,
  DeleteQuotation,
  GenereateId,
  ExportQuotation,
  PdfExportQuotation,
  GetQuotationTerms,
  CreateQuotationTerms,
};
