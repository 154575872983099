import React, { useMemo } from "react";
import QuotationTableHeadRow from "./QuotationTableHeadRow";
import EmptyStateRow from "./EmptyStateRow";
import QuotationProductRow from "./QuotationProductRow";
import QuotationSummaryStatsRow from "./QuotationSummaryStatsRow";
import ProductItemAddRow from "./ProductItemAddRow";
import QuotationProductItemRow from "./QuotationProductItemRow";
import QuotationManifoldRow from "./QuotationManifoldRow";

const columns = [
  {
    title: "Sr. No.",
    dataIndex: "id",
  },
  {
    title: "Code",
    dataIndex: "code",
    width: "15%",
  },
  {
    title: "Description",
    dataIndex: "description",
    width: "30%",
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    width: "6%",
  },
  {
    title: "Rate ₹",
    dataIndex: "price",
    width: "6%",
  },
  {
    title: "Discount %",
    dataIndex: "sales_discount",
    width: "6%",
  },
  {
    title: "Net ₹",
    dataIndex: "sales_net_amount",
    width: "6%",
  },
  {
    title: "Total ₹",
    dataIndex: "sales_total_amount",
    width: "6%",
  },
  {
    title: "Purchase Discount %",
    dataIndex: "purchase_discount",
  },
  {
    title: "Purchase Net ₹",
    dataIndex: "purchase_net_amount",
  },
  {
    title: "Purchase Total ₹",
    dataIndex: "purchase_total_amount",
  },
];

function QuotationTable({
  readOnly,
  productList,
  manifoldProductList,
  productBrandCodeMapper,
  addProductItem,
  removeProductItem,
  handleProjectItemCalculations,
  handleManifoldCalculations,
  asyncOnChange,
  asyncSelectLoadOptions,
  setReplaceBrandCode,
}) {
  const quotationItems = useMemo(() => {
    const quotationItems = [...productList, ...manifoldProductList];
    return [...quotationItems];
  }, [productList, manifoldProductList]);
  return (
    <>
      <table
        className={`table fixed_header table-borderedless table-hover table-sm text-center`}
      >
        <QuotationTableHeadRow {...{ columns }} />
        <tbody>
          {quotationItems?.length ? (
            <>
              {quotationItems?.map((product, index) => {
                if (product?.action === "delete") {
                  return <></>;
                }
                if (product.is_manifold) {
                  return (
                    <>
                      <QuotationManifoldRow
                        {...{
                          readOnly,
                          disabled: product?.action === "delete",
                          product,
                          columns,
                          productLength: productList?.length,
                          index: index,
                          handleManifoldCalculations,
                        }}
                      />
                    </>
                  );
                }
                let codeList = productBrandCodeMapper?.[product?.brand_code];
                const productItems = product?.productItems;
                return (
                  <>
                    <QuotationProductRow
                      {...{
                        product,
                        columns,
                        index,
                        readOnly,
                        disabled: product?.action === "delete",
                        asyncOnChange,
                        asyncSelectLoadOptions,
                        setReplaceBrandCode,
                        handleProjectItemCalculations
                      }}
                    />
                    {!!productItems?.length &&
                      productItems?.map((productItem, itemIndex) => {
                        // codeList = codeList?.filter(
                        //   (item) => productItem.code !== item?.code
                        // );
                        const data = productItem;
                        if (data?.action === "delete") {
                          return <></>;
                        }
                        return (
                          <QuotationProductItemRow
                            {...{
                              removeProductItem,
                              handleProjectItemCalculations,
                              columns,
                              data,
                              index: itemIndex,
                              mainProductIndex: index,
                              readOnly,
                              disabled: productItem?.action === "delete",
                            }}
                          />
                        );
                      })}
                    {!readOnly && (
                      <ProductItemAddRow
                        {...{
                          product,
                          columns,
                          index,
                          codeList,
                          addProductItem,
                        }}
                      />
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <EmptyStateRow />
          )}
          {!!quotationItems?.length && (
            <QuotationSummaryStatsRow
              {...{
                readOnly,
                productList,
                manifoldProductList,
              }}
            />
          )}
        </tbody>
      </table>

      {!!manifoldProductList?.length && (
        <>
          <b>Manifold Information</b>
          <table
            className={`table fixed_header table-borderedless table-hover table-sm text-center`}
          >
            <QuotationTableHeadRow {...{ columns, readOnly }} />
            <tbody>
              {manifoldProductList?.map((product, index) => {
                const productItems = product?.productItems;
                if (product?.action === "delete") {
                  return <></>;
                }
                return (
                  <>
                    <tr>
                      <td>
                        <div className="form-group">
                          {productList?.length + index + 1}{" "}
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="form-group">
                          {/* <b>Manifold Description</b> */}
                          <b>{product?.manifold_desc}</b>
                        </div>
                      </td>
                    </tr>
                    {!!productItems?.length && (
                      <>
                        {productItems?.map((productItem, itemIndex) => {
                          const data = productItem;
                          if (data?.action === "delete") {
                            return <></>;
                          }
                          return (
                            <QuotationProductItemRow
                              {...{
                                removeProductItem,
                                handleProjectItemCalculations,
                                columns,
                                data,
                                index: itemIndex,
                                mainProductIndex: index,
                                normalQuotationProductLength:
                                  productList?.length,
                                readOnly,
                                disabled: productItem?.action === "delete",
                              }}
                            />
                          );
                        })}
                        <QuotationSummaryStatsRow
                          {...{
                            productList: [],
                            manifoldProductList: productItems,
                          }}
                        />
                      </>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

export default React.memo(QuotationTable);
