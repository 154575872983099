import React, { Component } from "react";
import { connect } from "react-redux";
import "./AdminProductStyle.scss";
import { Breadcrumb, SimpleCard } from "@gull";
import { Tab, Nav, Button } from "react-bootstrap";
import {
  fetchProductById,
  createNewProduct,
  updateNewProduct,
  fetchProductWithFiltersV2,
  fetchProductBrandCodeList,
} from "../../../redux/actions/ProductActions";
import Spin from "@gull/components/Spin";
import { Formik, withFormik } from "formik";
import * as yup from "yup";
import FileUpload from "app/GullLayout/SharedComponents/fileupload/FileUpload";
import moment from "moment";
import { storageUrl } from "environment/env";
import Image from "app/GullLayout/SharedComponents/Image";
import { FILE_FORMATS, MEDIA_TAB } from "@utils";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";

const ProductFormSchema = yup.object().shape({
  brand_code: yup.string().required("Brand Code is required"),
  brand: yup.string().required("Brand is required"),
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  code: yup.string().required("item code is required"),
  size: yup.string().required("Size is required"),
  price: yup.string().required("Price is required"),
  sales_discount: yup.string().required("sales discount is required"),
  purchase_discount: yup.string().required("purchase discount is required"),
  pdf_url: yup.string().nullable(),
  image_url: yup.string().nullable(),
  external_link: yup.string().nullable(),
  presure_rate: yup.string().nullable(),
  disc_moc: yup.string().nullable(),
});

export class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: this.props?.match?.params?.productid,
      productData: this.props?.location?.state?.row,
      formLoading: false,
      formData: {
        brand_code: "",
        brand: "",
        name: "",
        description: "",
        code: "",
        size: "",
        price: "",
        sales_discount: "",
        purchase_discount: "",
        disc_moc: "",
        pdf_url: "",
        image_url: "",
        external_link: "",
        presure_rate: "",
      },
      loading: false,
      isImageEdit: false,
      isPDFEdit: false,
      searchedProducts: [],
    };
  }
  componentDidMount() {
    if (this.state.productId) {
      // this.props.fetchProductById(this.state.productId);
      this.setState({
        formData: {
          ...this.state.formData,
          ...this.state.productData,
        },
      });
    } else {
      this.setState({
        isImageEdit: true,
        isPDFEdit: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.productDetails !== prevProps.productDetails) {
      const { productDetails } = this.props;
      let product = {};
      if (productDetails?.length) {
        product = productDetails[0];
        this.setState({
          isImageEdit: !!product.image_url ? false : true,
          isPDFEdit: !!product.pdf_url ? false : true,
          formData: {
            ...this.state.formData,
            id: product.id,
            brand_code: product.brand_code,
            brand: product.brand,
            name: product.name,
            description: product.description,
            size: product.size,
            price: product.price,
            sales_discount: product.sales_discount,
            purchase_discount: product.purchase_discount,
            pdf_url: product.pdf_url,
            image_url: product.image_url,
            external_link: product.external_link,
            presure_rate: product.presure_rate,
            disc_moc: product.disc_moc,
          },
        });
      }
    }
  }

  handleSubmit = (value, { isSubmitting }) => {
    const that = this;
    this.setState(
      {
        formLoading: true,
      },
      () => {
        const payload = {
          ...that.state.formData,
          ...value,
        };

        if (that.state.productId) {
          payload["updated_at"] = moment();
        }

        const resp = that.state.productId
          ? that.props.updateNewProduct
          : that.props.createNewProduct;
        resp(payload).then((resp) => {
          if (resp) {
            that.setState(
              {
                formLoading: false,
              },
              () => {
                that.props.history.push("/product");
              }
            );
          }
        });
      }
    );
  };

  asyncSelectLoadOptions = debounce((inputValue, cb) => {
    if (!inputValue) {
      cb([]);
      return false;
    }
    this.props
      .fetchProductBrandCodeList({
        brand_code: inputValue,
      })
      .then((resp) => {
        cb(
          !!inputValue
            ? resp?.data?.data?.map((item) => ({
                label: item?.brand_code,
                value: item?.brand_code,
              })) || []
            : []
        );
      });
  }, 300);

  asyncOnChange(selectedOption, action) {
    if (action?.action === "select-option") {
      if (!!selectedOption?.value && !!selectedOption?.value?.length) {
        this.props.fetchProductWithFiltersV2({
          brand_code: selectedOption?.value,
        });
      }
    }
  }

  onUploadFinish = (url, type) => {
    let key = "image_url";
    switch (type) {
      case "product":
        key = "image_url";
        break;
      case "product-pdf":
        key = "pdf_url";
        break;
      default:
        key = "image_url";
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: url,
      },
    });
  };

  handleFormValueChange = (e) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          [e.target.name]: e.target.value,
        },
      },
      () => console.log(this.props.errors)
    );
  };
  render() {
    const { loading } = this.props;
    const { formLoading, searchedProducts, productId } = this.state;
    return (
      <div className="product-form">
        <Breadcrumb
          routeSegments={[
            { name: `${!!productId ? "Edit" : "Add"} Product`, path: "/" },
          ]}
          isBackButton={true}
        ></Breadcrumb>
        <Spin loading={loading | formLoading}>
          <Formik
            enableReinitialize
            initialValues={this.state.formData}
            validationSchema={ProductFormSchema}
            onSubmit={this.handleSubmit}
            handleChange={this.handleFormValueChange}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setValues,
              isSubmitting,
            }) => {
              return (
                <form
                  onSubmit={(e) => {
                    console.log(errors);
                    handleSubmit(e);
                  }}
                >
                  <div className="row mb-5">
                    <div className="col-md-6">
                      <SimpleCard title="Product Info" className="mb-3">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <b>
                                  Brand Code
                                  <span style={{ color: "red" }}>*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                {!productId && <div className="form-group">
                                  <AsyncSelect
                                    styles={{
                                      menu: (style) => ({
                                        ...style,
                                        zIndex: 99,
                                      }),
                                    }}
                                    isDisabled={!!productId}
                                    placeholder={`Search Brand Code`}
                                    cacheOptions={false}
                                    defaultOptions
                                    value={searchedProducts}
                                    onChange={(selectedOption, action) =>
                                      this.asyncOnChange(
                                        selectedOption,
                                        action,
                                        true
                                      )
                                    }
                                    loadOptions={this.asyncSelectLoadOptions}
                                  />
                                </div>}
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="brand_code"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.brand_code}
                                    placeholder="brand Code"
                                  />
                                  {errors.brand_code && touched.brand_code && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.brand_code}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>
                                  Brand<span style={{ color: "red" }}>*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="brand"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.brand}
                                    placeholder="Brand"
                                  />
                                  {errors.brand && touched.brand && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.brand}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>
                                  Name<span style={{ color: "red" }}>*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="name"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    placeholder="name"
                                  />
                                  {errors.name && touched.name && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>
                                  Description
                                  <span style={{ color: "red" }}>*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="description"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    placeholder="Description"
                                  />
                                  {errors.description &&
                                    touched.description && (
                                      <div className="text-danger mt-1 ml-1">
                                        {errors.description}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>
                                  Item Code
                                  <span style={{ color: "red" }}>*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="code"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.code}
                                    placeholder="Item Code"
                                  />
                                  {errors.code && touched.code && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.code}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>
                                  Size<span style={{ color: "red" }}>*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="size"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.size}
                                    placeholder="Size"
                                  />
                                  {errors.size && touched.size && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.size}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>
                                  Price<span style={{ color: "red" }}>*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="price"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.price}
                                    placeholder="Price"
                                  />
                                  {errors.price && touched.price && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.price}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>
                                  Sales Discount (%)
                                  <span style={{ color: "red" }}>*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="sales_discount"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.sales_discount}
                                    placeholder="Sales Discount"
                                  />
                                  {errors.sales_discount &&
                                    touched.sales_discount && (
                                      <div className="text-danger mt-1 ml-1">
                                        {errors.sales_discount}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>
                                  Purchase Discount (%)
                                  <span style={{ color: "red" }}>*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="purchase_discount"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.purchase_discount}
                                    placeholder="Purchase Discount"
                                  />
                                  {errors.purchase_discount &&
                                    touched.purchase_discount && (
                                      <div className="text-danger mt-1 ml-1">
                                        {errors.purchase_discount}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>Pressure Rate</b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="presure_rate"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.presure_rate}
                                    placeholder="Pressure Rate"
                                  />
                                  {errors.presure_rate &&
                                    touched.presure_rate && (
                                      <div className="text-danger mt-1 ml-1">
                                        {errors.presure_rate}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>Disc Moc</b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="disc_moc"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.disc_moc}
                                    placeholder="Disc Moc"
                                  />
                                  {errors.disc_moc && touched.disc_moc && (
                                    <div className="text-danger mt-1 ml-1">
                                      {errors.disc_moc}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <b>External Link</b>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="external_link"
                                    onChange={(e) => {
                                      this.handleFormValueChange(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.external_link}
                                    placeholder="External Link"
                                  />
                                  {errors.external_link &&
                                    touched.external_link && (
                                      <div className="text-danger mt-1 ml-1">
                                        {errors.external_link}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SimpleCard>
                    </div>
                    <div className="col-md-6">
                      <SimpleCard className="mb-3">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="IMAGE"
                        >
                          <Nav variant="pills" className="d-flex px-2">
                            <Nav.Item className="mr-2 flex-grow-1 text-center">
                              <Nav.Link eventKey={MEDIA_TAB.IMAGE}>
                                Upload Image
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mr-2 flex-grow-1 text-center"></Nav.Item>
                            <Nav.Item className="mr-2 flex-grow-1 text-center"></Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey={MEDIA_TAB.IMAGE}>
                              {!this.state.isImageEdit ? (
                                !!this.state.formData?.image_url &&
                                FILE_FORMATS.find((i) => {
                                  return this.state.formData?.image_url
                                    ? this.state.formData?.image_url.includes(i)
                                    : false;
                                }) ? (
                                  <Image
                                    src={`${storageUrl}${this.state.formData?.image_url}`}
                                    class="img-fluid"
                                    alt={"stone image"}
                                  />
                                ) : !!this.state.formData?.image_url ? (
                                  <iframe
                                    title="image"
                                    src={this.state.formData?.image_url}
                                    width={"100%"}
                                    style={{ height: "60vh" }}
                                  />
                                ) : (
                                  <img
                                    width="100px"
                                    src={`${process.env.PUBLIC_URL}/assets/images/no-image.jpg`}
                                    alt="no pic found"
                                  />
                                )
                              ) : (
                                <FileUpload
                                  initalData={this.state.formData?.image_url}
                                  allowedFiles={FILE_FORMATS}
                                  isServerUpload={true}
                                  onUploadFinish={this.onUploadFinish}
                                  filepath="product"
                                />
                              )}
                              <br />
                              <Button
                                variant={`primary`}
                                className="btn-rounded m-1 text-capitalize"
                                onClick={(e) =>
                                  this.setState({
                                    isImageEdit: !this.state.isImageEdit,
                                  })
                                }
                              >
                                {this.state.isImageEdit
                                  ? "Cancel"
                                  : "Re-Upload Image"}
                              </Button>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </SimpleCard>
                      <SimpleCard>
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="PDF"
                        >
                          <Nav variant="pills" className="d-flex px-2">
                            <Nav.Item className="mr-2 flex-grow-1 text-center">
                              <Nav.Link eventKey={MEDIA_TAB.PDF}>
                                Upload PDF
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mr-2 flex-grow-1 text-center"></Nav.Item>
                            <Nav.Item className="mr-2 flex-grow-1 text-center"></Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey={MEDIA_TAB.PDF}>
                              {!this.state.isPDFEdit ? (
                                !!this.state.formData?.pdf_url &&
                                FILE_FORMATS.find((i) => {
                                  return this.state.formData?.pdf_url
                                    ? this.state.formData?.pdf_url.includes(i)
                                    : false;
                                }) ? (
                                  <Image
                                    src={`${storageUrl}${this.state.formData?.pdf_url}`}
                                    class="img-fluid"
                                    alt={"stone image"}
                                  />
                                ) : !!this.state.formData?.pdf_url ? (
                                  <iframe
                                    title="image"
                                    src={`${storageUrl}${this.state.formData?.pdf_url}`}
                                    width={"100%"}
                                    style={{ height: "60vh" }}
                                  />
                                ) : (
                                  <img
                                    width="100px"
                                    src={`${process.env.PUBLIC_URL}/assets/images/no-image.jpg`}
                                    alt="No pci found"
                                  />
                                )
                              ) : (
                                <FileUpload
                                  initalData={this.state.formData?.pdf_url}
                                  allowedFiles={[".pdf"]}
                                  isServerUpload={true}
                                  onUploadFinish={this.onUploadFinish}
                                  filepath="product-pdf"
                                />
                              )}
                              <br />
                              <Button
                                variant={`primary`}
                                className="btn-rounded m-1 text-capitalize"
                                onClick={(e) =>
                                  this.setState({
                                    isPDFEdit: !this.state.isPDFEdit,
                                  })
                                }
                              >
                                {this.state.isPDFEdit
                                  ? "Cancel"
                                  : "Re-Upload PDF"}
                              </Button>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </SimpleCard>
                    </div>
                  </div>
                  <div className="sticky-footer">
                    <div className="container">
                      <div className="">
                        <Button
                          key={"primary3"}
                          variant={`primary`}
                          className="btn-rounded m-1 text-capitalize"
                          style={{ float: "right" }}
                          type="submit"
                        >
                          Upload Product
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productDetails: state.product?.productDetails,
  loading: state.product?.loading,
  user: state.user,
});

const mapDispatchToProps = {
  fetchProductById,
  createNewProduct,
  updateNewProduct,
  fetchProductWithFiltersV2,
  fetchProductBrandCodeList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFormik({})(ProductForm));
