import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import AppModal from '../modal/Modal';
import { bulkProductImagesUpload } from '../../../services/ProductService';
import { NotificationManager } from 'react-notifications';
import Spin from '@gull/components/Spin';
import FileUpload from '../fileupload/FileUpload';
import { FILE_FORMATS } from '@utils'

class BulkProductImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openUploadModal: false,
            bulkProductImageUploadLoading: false,
            filepath: 'diamond'
        }
    }
    openImageUploadModal = () => {
        this.setState({
            openImageUploadModal: !this.state.openImageUploadModal
        })
    }
    bulkProductImageUpload = (fileurl, filepath) => {
        this.setState({
            bulkProductImageUploadLoading: true
        }, () => {
            setTimeout(() => {

                const payload = new FormData();
                Array.from(fileurl).forEach(file => {
                    payload.append('file[]', file);
                })
                payload.append('filepath', filepath);
                bulkProductImagesUpload(payload)
                    .then(resp => {
                        if (resp.data.success) {
                            this.openImageUploadModal();
                            if (this.props.getProductData)
                                this.props.getProductData();
                            NotificationManager.success('new diamonds images uploaded', 'SUCCESS');
                        }
                        this.setState({
                            bulkProductImageUploadLoading: false
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({
                            bulkProductImageUploadLoading: false
                        })
                        NotificationManager.error('something went wrong. Please contact admin');
                    })
            }, 500)
        })
    }
    render() {
        const { openImageUploadModal, bulkProductImageUploadLoading } = this.state
        const { className } = this.props;
        return (
            <div>
                <Button
                    key={99}
                    variant={`outline-primary`}
                    className={`btn-rounded m-1 text-capitalize right-float-patch ${className}`}
                    onClick={e => this.openImageUploadModal()}
                >
                    Upload Product Images
            </Button>
                <AppModal
                    show={openImageUploadModal}
                    title={'Upload Products Images'}
                    size="md"
                    onHide={() => this.setState({ openImageUploadModal: false })}
                >
                    <Spin loading={bulkProductImageUploadLoading}>
                        <p>What are you uploading?</p>
                        <div className="form-group">
                            <select
                                id="picker1"
                                className="form-control"
                                name="featured"
                                onChange={e => { this.setState({ filepath: e.target.value }) }}
                                value={this.state.filepath}
                            >
                                <option value="diamond">Image</option>
                                <option value="pdf">PDF</option>
                            </select>
                        </div>
                        <FileUpload allowedFiles={(this.state.filepath === 'pdf') ? ['.pdf'] : FILE_FORMATS} isMultiple={true} isServerUpload={false} onUploadFinish={this.bulkProductImageUpload} filepath={this.state.filepath} />
                    </Spin>
                </AppModal>
            </div>
        )
    }
}


export default BulkProductImageUpload