import Image from "app/GullLayout/SharedComponents/Image";
import { storageUrl } from "environment/env";
import React from "react";
import AsyncSelect from "react-select/async";

function QuotationProductRow({
  disabled,
  readOnly,
  columns,
  product,
  index,
  asyncSelectLoadOptions,
  asyncOnChange,
  setReplaceBrandCode,
  handleProjectItemCalculations,
}) {
  const style = {
    width: "auto",
    whiteSpace: "pre-wrap",
  };
  return (
    <tr className={disabled ? "disabled" : ""}>
      {columns.map((col, i) => {
        let colStyle = {
          ...style,
        };
        if (col.width) {
          colStyle = {
            ...colStyle,
            width: col.width,
          };
        }

        switch (col.dataIndex) {
          case "id":
            return (
              <td style={colStyle} className={col.className}>
                {index + 1}
              </td>
            );
          case "code":
            return (
              <td key={i} style={colStyle} className={col.className}>
                {readOnly ? (
                  <div className="form-group">
                    <div
                      className="form-control position-relative"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      {product?.brand_code}
                    </div>
                  </div>
                ) : (
                  <AsyncSelect
                    styles={{
                      menu: (style) => ({
                        ...style,
                        zIndex: 99,
                      }),
                    }}
                    placeholder={product?.brand_code}
                    cacheOptions
                    defaultOptions
                    onChange={(newValue, actionMeta) => {
                      setReplaceBrandCode(product?.brand_code + ":" + index);
                      asyncOnChange(newValue, actionMeta);
                    }}
                    loadOptions={asyncSelectLoadOptions}
                  />
                )}
              </td>
            );
          case "image_url":
            return (
              <td>
                <Image
                  src={encodeURI(
                    `${storageUrl}${product?.productItems?.[0]?.product_item?.image_url}`
                  )}
                  class="img-fluid"
                  style={{
                    width: 200,
                  }}
                  alt={product?.name}
                />
              </td>
            );
          case "description":
            return (
              <td key={i} style={colStyle} className={col.className}>
                <div className="form-group">
                  {product?.description}
                  <p>
                    <b>Make: {product?.brand}</b>
                  </p>
                  {readOnly ? (
                    <div className="form-group">{product?.comment}</div>
                  ) : (
                    <div className="form-group">
                      <input
                        className="form-control position-relative"
                        type="text"
                        name={"comment"}
                        onChange={(e) => {
                          handleProjectItemCalculations(
                            false,
                            index,
                            index,
                            null,
                            "comment",
                            e.target?.value ?? ""
                          );
                        }}
                        value={product?.comment}
                        placeholder={"comment"}
                      />
                    </div>
                  )}
                </div>
              </td>
            );
          default:
            return <td key={i} style={colStyle} className={col.className}></td>;
        }
      })}
    </tr>
  );
}

export default React.memo(QuotationProductRow);
