import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { envType } from "environment/env";

if (envType === "prod") {
  console.log = () => {};
  // ConsoleAction(true);
  const clarityScript = document.createElement("script");
  clarityScript.type = "text/javascript";
  clarityScript.innerHTML = `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "jdwvr342wh");`;
  window.document.head.appendChild(clarityScript);
}
// window.onbeforeunload = function () {
//   return 'Are you sure you want to leave?';
// }

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
