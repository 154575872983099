import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';

class AppModal extends Component {


    render() {
        return (
            <Modal {...this.props}>
                {
                    this.props.title && <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                }
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                {
                    this.props.footer && <Modal.Footer>
                        {this.props.footer}
                    </Modal.Footer>
                }
            </Modal>
        )
    }
}

export default AppModal;
