/* eslint-disable */
import React, { Component } from "react";
import Table from "app/GullLayout/SharedComponents/table/Table";
import "./AdminQuatationsStyle.scss";
import { Breadcrumb } from "@gull";
import { Button, Badge, Dropdown } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Spin from "@gull/components/Spin";
import { connect } from "react-redux";
import {
  fetchQuotations,
  deleteQuoation,
  softDeleteQuoation,
} from "../../../redux/actions/QuotationActions";

class AdminQuatations extends Component {
  state = {
    windowWidth: window.innerWidth,
    columns: [
      {
        title: "",
        dataIndex: "Action",
        width: 100,
        render: (data, row) => (
          <>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">Action</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    this.props.history.push(
                      `/quotation/form/${window.btoa(row.id)}`
                    );
                  }}
                  disabled={row?.status === 0}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    this.softDeleteQuoation(row);
                  }}
                >
                  {!!row?.status ? "Inactivate" : "Activate"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    this.deleteQuoation(row);
                  }}
                >
                  Delete
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    this.props.history.push(`/quotation/form`, {
                      data: row,
                      codeType: "REVISE",
                    });
                  }}
                  disabled={row?.status === 0}
                >
                  Revise Quotation
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    this.props.history.push(`/quotation/form`, {
                      data: row,
                      codeType: "OPTION",
                    });
                  }}
                  disabled={row?.status === 0}
                >
                  Option Quatation
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    this.props.history.push(`/quotation/form`, {
                      data: row,
                      codeType: 'COPY',
                    });
                  }}
                  disabled={row?.status === 0}
                >
                  Copy Quatation
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        ),
      },
      {
        title: "Code",
        dataIndex: "code",
        render: (data, row) => (
          <Link to={`/quotation/${window.btoa(row.id)}`}>{data ?? "-"}</Link>
        ),
      },
      {
        title: "Project Name",
        dataIndex: "project_name",
      },
      {
        title: "Party Name",
        dataIndex: "party_name",
        render: (data, row) => <div>{row?.customer?.name ?? "-"}</div>,
      },
      {
        title: "Kind Attend.",
        dataIndex: "party_name",
        render: (data, row) => <div>{row?.kind_attend ?? "-"}</div>,
      },
      {
        title: "Contact Number",
        dataIndex: "contact_number",
        render: (data, row) => <div>{row?.contact_number ?? "-"}</div>,
      },
      {
        title: "Status",
        dataIndex: "status",
        className: "",
        render: (data) =>
          Number(data) ? (
            <Badge key={1} className={`badge-success text-white p-2`}>
              {"ACTIVE"}
            </Badge>
          ) : (
            <Badge key={2} className={`badge-danger text-white p-2`}>
              {"INACTIVE"}
            </Badge>
          ),
      },
      {
        title: "Created On",
        dataIndex: "created_at",
      },
      {
        title: "Last Update On",
        dataIndex: "updated_at",
      },
    ],
    selectedRow: {},
    selectedProducts: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getQuotationData();
  }

  componentDidUpdate(prevProps) {}

  getQuotationData = (filters = {}) => {
    this.props.fetchQuotations({ page: 1, ...filters });
  };

  onPageChange = (page) => {
    this.getQuotationData({ page: page + 1 });
  };

  searchQuotation = (e) => {
    e.preventDefault();
    this.getQuotationData({ code: this.state.searchVal });
    // if (isNaN(this.state.searchVal)) {
    //   // this.getQuotationData({ party_name: this.state.searchVal });
    // } else {
    //   this.getQuotationData({ code: this.state.searchVal });
    // }
  };
  resetSearchQuotation = (e) => {
    e.preventDefault();
    this.setState(
      {
        searchVal: "",
      },
      () => this.getQuotationData({})
    );
  };

  deleteQuoation = (row) => {
    const payload = {
      id: row.id,
    };
    this.props.deleteQuoation(payload).then((resp) => {
      this.getQuotationData();
    });
  };
  softDeleteQuoation = (row) => {
    const payload = {
      id: row.id,
      status: !row.status,
    };
    this.props.softDeleteQuoation(payload).then((resp) => {
      this.getQuotationData();
    });
  };

  render() {
    const { columns } = this.state;
    const { loading, quotationList, currentPage, totalRow, perPage } =
      this.props;
    const buttonList = [
      <Button
        key={1}
        variant={`primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push("/quotation/form")}
      >
        Create Quotation
      </Button>,
      <Button
        key={1}
        variant={`primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push("/quotation/summary")}
      >
        Quotation Summary
      </Button>,
      // <Button
      //   key={1}
      //   variant={`primary`}
      //   className="btn-rounded m-1 text-capitalize right-float-patch"
      //   style={{ float: "right" }}
      //   onClick={(e) => this.props.history.push("/quotation/report")}
      // >
      //   Generate Reports
      // </Button>,
    ];
    const buttonListmerge = [
      <Button
        key={1}
        variant={`primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push("/quotation/form")}
      >
        Create Quotation
      </Button>,
      <Button
        key={1}
        variant={`primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push("/quotation/summary")}
      >
        Quotation Summary
      </Button>,
      // <Button
      //   key={1}
      //   variant={`primary`}
      //   className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
      //   style={{ float: "right" }}
      //   onClick={(e) => this.props.history.push("/quotation/report")}
      // >
      //   Generate Reports
      // </Button>,
    ];
    let switchlist = [];
    if (this.state.windowWidth > 600) {
      switchlist = [...switchlist, ...buttonListmerge];
    }
    return (
      <div className="admin-users">
        <Breadcrumb
          routeSegments={[{ name: "Quotation List", path: "/quotations" }]}
          extra={switchlist}
          patchingclass={"pad-2rem-petch"}
          switchlist_bc={buttonList}
        ></Breadcrumb>
        <div className="row pad-2rem-petch">
          <div className="col-md-4 form-group mb-3">
            <form onSubmit={(e) => this.searchQuotation(e)}>
              <div class="input-group border rounded-pill">
                <input
                  type="search"
                  value={this.state.searchVal}
                  onChange={(e) => this.setState({ searchVal: e.target.value })}
                  placeholder="Search by Quotation Code"
                  class="form-control form-control-rounded bg-none border-0"
                />
                <div class="input-group-append border-0">
                  {!!this.state.searchVal && (
                    <button
                      type="button"
                      onClick={this.resetSearchQuotation}
                      class="btn btn-outline-primary btn-rounded"
                    >
                      {" "}
                      <i className="fa fa-times" />{" "}
                    </button>
                  )}
                  <button type="submit" class="btn btn-primary btn-rounded">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-12 col-md-12">
            <Spin loading={loading}>
              <Table
                dataSource={quotationList}
                bordered={true}
                columns={columns}
                // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                paginate={false}
              />
            </Spin>
          </div>
        </div>
        <div className="breaker"></div>
        <div className="sticky-footer">
          <div className="container">
            <div className="d-flex justify-content-center mt-3 mb-3">
              <ReactPaginate
                previousLabel={<i className="i-Previous"></i>}
                nextLabel={<i className="i-Next1"></i>}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.round(
                  totalRow / perPage >= 1 ? totalRow / perPage : 1
                )}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={(page) => this.onPageChange(page.selected)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={currentPage - 1 ? currentPage - 1 : 0}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.quotation.loading,
  quotationList: state.quotation?.data,
  currentPage: state.quotation?.current_page,
  totalRow: state.quotation?.total,
  perPage: state.quotation?.per_page,
});
const mapDispatchToProps = {
  fetchQuotations,
  deleteQuoation,
  softDeleteQuoation,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminQuatations));
