import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import AppModal from '../modal/Modal';
import { bulkProductUpload } from '../../../services/ProductService';
import { NotificationManager } from 'react-notifications';
import Spin from '@gull/components/Spin';
import FileUpload from '../fileupload/FileUpload';
import { storageUrl } from 'environment/env';

class BulkProductUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openUploadModal: false,
            bulkProductUploadLoading: false
        }
    }
    openUploadModal = () => {
        this.setState({
            openUploadModal: !this.state.openUploadModal
        })
    }
    bulkProductUpload = (fileurl, filepath) => {
        this.setState({
            bulkProductUploadLoading: true
        }, () => {
            setTimeout(() => {
                const payload = new FormData();
                payload.append('file', fileurl[0]);
                bulkProductUpload(payload)
                    .then(resp => {
                        if (resp.data.data.success) {
                            this.openUploadModal();
                            if (this.props?.getProductData)
                                this.props.getProductData();
                            NotificationManager.success('new products uploaded', 'SUCCESS');
                        } else {
                            NotificationManager.error(resp.data?.data?.error ? resp.data.data.error : 'something went wrong. Please contact admin');
                        }
                        this.setState({
                            bulkProductUploadLoading: false
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({
                            bulkProductUploadLoading: false
                        })
                        NotificationManager.error('something went wrong. Please contact admin');
                    })
            }, 500)
        })
    }
    render() {
        const { openUploadModal } = this.state;
        const { className } = this.props
        return (
            <div>
                <Button
                    key={99}
                    variant={`outline-primary`}
                    className={`btn-rounded m-1 text-capitalize right-float-patch ${className}`}
                    onClick={e => this.openUploadModal()}
                >
                    Upload Product List
            </Button>
                <AppModal
                    show={openUploadModal}
                    title={'Upload Products'}
                    size="md"
                    onHide={() => this.setState({ openUploadModal: false })}
                >
                    <Spin loading={this.state.bulkProductUploadLoading}>
                        <p className="m-1 ml-2 h5">Download Sample format: <a href={`${storageUrl}/storage/samples/product-sample-upload.xlsx`} download>here</a></p>
                        <small className="m-1 ml-2" style={{ color: 'red' }}>[Note: Please upload only <b>.csv</b> file</small><br/>
                        <small className="m-1 ml-2" style={{ color: 'red' }}>Make sure to upload all the images and pdf before uploading products]</small>
                        <FileUpload allowedFiles={['.csv']} isMultiple={false} isServerUpload={false} onUploadFinish={this.bulkProductUpload} />
                    </Spin>
                </AppModal>
            </div>
        )
    }
}


export default BulkProductUpload