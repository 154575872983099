import { Breadcrumb, SimpleCard } from "@gull";
import Spin from "@gull/components/Spin";
import { getCurrentFinancialYear } from "@utils";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchQuotations,
  exportQuotation,
  pdfExportQuotation,
} from "../../../redux/actions/QuotationActions";
import QuotationTable from "./component/QuotationTable";
import { Button, Tab, Tabs } from "react-bootstrap";
import { storageUrl } from "environment/env";

class QuotationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationId: this.props?.match?.params?.quotationId,
      formLoading: false,
      formData: {
        code: !!this.props?.location?.state?.data
          ? this.props?.location?.state?.data?.code + "-R"
          : `NI/QA/${getCurrentFinancialYear()}/${moment()
              .valueOf()
              .toString()
              .slice(-5)}`,
        party_id: undefined,
        project_name: undefined,
      },
      is_manifold: false,
      selectedManifold: "",
      searchedProjectCode: "",
      productList: [],
      manifoldProductList: [],
      productBrandCodeMapper: {},
      searchedProducts: [],
      isNewBrandCodeAdd: true,
      replaceBrandCode: "",
    };
    if (this.props?.location?.state?.data) {
      this.props.fetchQuotations({
        id: window.btoa(this.props?.location?.state?.data?.id),
      });
    }
  }

  componentDidMount() {
    if (this.state.quotationId) {
      this.props.fetchQuotations({ id: this.state.quotationId });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.quotationData !== prevProps.quotationData) {
      const { quotationData } = this.props;
      this.proccessQuotationData(quotationData);
    }
  }

  priceCalcuations(productData) {
    const quotation_price = Math.round(
      Number(productData?.price ?? productData?.quotation_price ?? "0")
    );
    return {
      ...productData,
      price: quotation_price,
      quotation_price,
    };
  }

  quotationProductDataTransformer(products, is_manifold) {
    if (!Array.isArray(products)) {
      return;
    }

    return products?.map((product) => {
      return {
        ...product,
        is_manifold: is_manifold,
        productItems: product?.productItems?.map((newProdItem) => {
          return {
            ...newProdItem,
            is_manifold,
            ...this.priceCalcuations(newProdItem),
          };
        }),
      };
    });
  }

  proccessQuotationData(quotationData) {
    let quotation = {};
    if (quotationData?.length) {
      quotation = quotationData[0];
      if (quotation?.id) {
        console.log(quotation.products);
        const entireList = [...quotation?.products, ...quotation?.manifolds];
        const manifoldList = [];
        const productList = [];
        for (let index = 0; index < entireList?.length; index++) {
          const prod = entireList[index];
          if (prod?.productItems?.[0]?.is_manifold === 1) {
            manifoldList.push({
              ...prod,
              is_manifold: true,
              ...this.priceCalcuations(prod),
              purchase_net_amount: Math.round(Number(prod.purchase_net_amount).toFixed(2)),
              purchase_total_amount: Math.round(Number(prod.purchase_total_amount).toFixed(2)),
              productItems: prod?.productItems?.map((item) => ({
                ...item,
                is_manifold: true,
              })),
            });
          } else {
            productList.push({
              ...prod,
              comment: prod?.productItems?.[0]?.comment,
            });
          }
        }
        this.setState({
          quotation,
          productList: this.quotationProductDataTransformer(productList, false),
          manifoldProductList: this.quotationProductDataTransformer(
            manifoldList,
            true
          ),
        });
      }
    }
  }

  render() {
    const { loading } = this.props;
    const {
      formLoading,
      productList,
      manifoldProductList,
      productBrandCodeMapper,
      quotation,
    } = this.state;
    return (
      <div className="quotation-form">
        <Breadcrumb
          routeSegments={[
            {
              name: `Quotation Details`,
              path: "/",
            },
          ]}
          isBackButton={true}
        />
        <Spin loading={loading | formLoading}>
          <div className="row mb-5">
            <div className="col-md-12">
              <SimpleCard title="" className="mb-3">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-3">
                        <b>Quotation Code</b>
                        <div className="form-group">
                          <div
                            className="form-control position-relative"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              paddingLeft: 0,
                            }}
                          >
                            {quotation?.code}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <b>Customer Name</b>
                        <div className="form-group">
                          <div
                            className="form-control position-relative"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              paddingLeft: 0,
                            }}
                          >
                            {quotation?.customer?.name}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <b>Project Name</b>
                        <div className="form-group">
                          <div
                            className="form-control position-relative"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              paddingLeft: 0,
                            }}
                          >
                            {quotation?.project_name}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <b>kind attend.</b>
                        <div className="form-group">
                          <div
                            className="form-control position-relative"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              paddingLeft: 0,
                            }}
                          >
                            {quotation?.kind_attend ?? "NA"}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <b>Contact Number</b>
                        <div className="form-group">
                          <div
                            className="form-control position-relative"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              paddingLeft: 0,
                            }}
                          >
                            {quotation?.contact_number ?? "NA"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Button
                      variant="outline-primary"
                      className="ml-2 float-right"
                      onClick={() => {
                        this.props.history.push(
                          `/quotation/form/${window.btoa(quotation.id)}`
                        );
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outline-primary"
                      className="ml-2 float-right"
                      onClick={() => {
                        this.setState(
                          {
                            formLoading: true,
                          },
                          () => {
                            const that = this;
                            this.props
                              .exportQuotation({
                                id: this.props?.match?.params?.quotationId,
                                code: quotation.code,
                                isDetailedReport: true,
                              })
                              .then((resp) => {
                                that.setState(
                                  {
                                    formLoading: false,
                                  },
                                  () => {
                                    if (resp?.success) {
                                      window.open(`${storageUrl}${resp?.url}`);
                                    } else {
                                      console.log("");
                                    }
                                  }
                                );
                              });
                          }
                        );
                      }}
                    >
                      Download Full Excel
                    </Button>
                    <Button
                      variant="outline-primary"
                      className="ml-2 float-right"
                      onClick={() => {
                        this.setState(
                          {
                            formLoading: true,
                          },
                          () => {
                            const that = this;
                            this.props
                              .exportQuotation({
                                id: this.props?.match?.params?.quotationId,
                                code: quotation.code,
                                isDetailedReport: false,
                              })
                              .then((resp) => {
                                that.setState(
                                  {
                                    formLoading: false,
                                  },
                                  () => {
                                    if (resp?.success) {
                                      window.open(`${storageUrl}${resp?.url}`);
                                    } else {
                                      console.log("");
                                    }
                                  }
                                );
                              });
                          }
                        );
                      }}
                    >
                      Download Excel
                    </Button>
                    <Button
                      variant="outline-primary"
                      className="float-right"
                      onClick={() => {
                        this.setState(
                          {
                            formLoading: true,
                          },
                          () => {
                            const that = this;
                            this.props
                              .pdfExportQuotation({
                                id: this.props?.match?.params?.quotationId,
                                code: quotation.code,
                              })
                              .then((resp) => {
                                that.setState(
                                  {
                                    formLoading: false,
                                  },
                                  () => {
                                    if (resp?.success) {
                                      const win = window.open();
                                      win.document.body.innerHTML = `${resp?.url}`;
                                      win.onafterprint = win.close;
                                      win.addEventListener("load", (event) => {
                                        win.print();
                                      });
                                    } else {
                                      console.log("");
                                    }
                                  }
                                );
                              })
                              .catch((err) => {
                                that.setState({
                                  formLoading: false,
                                });
                                console.log(err);
                              });
                          }
                        );
                      }}
                    >
                      Download PDF
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <Tabs
                      defaultActiveKey="home"
                      transition={false}
                      id="noanim-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="home" title="Quotation">
                        <QuotationTable
                          {...{
                            productList,
                            manifoldProductList,
                            productBrandCodeMapper,
                            readOnly: true,
                          }}
                        />
                      </Tab>
                      <Tab
                        eventKey="profile"
                        title="Terms & Conditions"
                        style={{ minHeight: 500 }}
                      >
                        {quotation?.quotation_terms?.length ? (
                          <ol>
                            {Array.from(
                              new Set(quotation?.quotation_terms)
                            )?.map((item) => {
                              return <li>{item?.term}</li>;
                            })}
                          </ol>
                        ) : (
                          <b>No Terms & condition found</b>
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </SimpleCard>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productItems: state.product?.productDetails,
  quotationData: state.quotation?.data,
  loading: state.quotation.loading,
  quotation: state.quotation,
  customerList: state.customer?.data,
  customerLoading: state.customer?.loading,
});

const mapDispatchToProps = {
  fetchQuotations,
  exportQuotation,
  pdfExportQuotation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(QuotationDetails));
