const layout2Settings = {
  leftSidebar: {
    theme: "sidebar-black",
    open: false,
    secondaryNavOpen: false,
  },
  header: {
    show: true
  },
  searchBox: {
    open: false
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

export default layout2Settings;