import { StoreFile } from "app/services/FileStorageService";

export const STORE_FILE_BEGIN = 'STORE_FILE_BEGIN';
export const STORE_FILE_SUCESS = 'STORE_FILE_SUCESS';
export const STORE_FILE_FAILED = 'STORE_FILE_FAILED';

export const storeFile = (data) => dispatch => {
    dispatch({
        type: STORE_FILE_BEGIN
    });
    StoreFile(data).then((resp) => {
        if (resp?.data?.url) {
            dispatch({
                type: STORE_FILE_SUCESS,
                payload: resp?.data
            })
        } else {
            dispatch({
                type: STORE_FILE_SUCESS,
                payload: { url: "" }
            })
        }
    })
};