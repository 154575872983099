import { STORE_FILE_SUCESS, STORE_FILE_BEGIN, STORE_FILE_FAILED } from "../actions/FileStorageActions";

const initialState = {
    loading: false,
};

const FileStorageReducer = function (state = initialState, action) {
    switch (action.type) {
        case STORE_FILE_BEGIN: {
            return {
                ...state,
                loading: true,
                url: undefined
            };
        }
        case STORE_FILE_SUCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload
            };
        }
        case STORE_FILE_FAILED: {
            return {
                ...state,
                loading: false,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default FileStorageReducer;
