import React, { Component } from "react";
import { Breadcrumb } from "@gull";
import Table from "app/GullLayout/SharedComponents/table/Table";
import { connect } from "react-redux";
import {
  fetchCustomers,
  DeleteCustomer,
} from "../../../redux/actions/CustomerActions";
import ReactPaginate from "react-paginate";
import Spin from "@gull/components/Spin";
import { Button, Dropdown } from "react-bootstrap";
import AddCustomer from "./modals/CreateCustomer";

class AdminCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      openCustomerModal: false,
      selectedCustomer: null,
      columns: [
        {
          title: "",
          dataIndex: "Action",
          width: 100,
          render: (data, row) => (
            <>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">Action</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      this.setState({
                        selectedCustomer: row,
                        openCustomerModal: true,
                      });
                    }}
                    disabled={row?.status === 0}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      this.props.DeleteCustomer(row).then(() => {
                        this.props.fetchCustomers();
                      });
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ),
        },
        {
          title: "Customer ID",
          dataIndex: "id",
          render: (data) => (data ? data : "-"),
        },
        {
          title: "Name",
          dataIndex: "name",
          render: (data) => (data ? data : "-"),
        },
        {
          title: "Contacted by",
          dataIndex: "contacted_by",
          render: (data) => (data ? data : "-"),
        },
        {
          title: "Phone",
          dataIndex: "phone",
          render: (data) => (data ? data : "-"),
        },
        {
          title: "Address",
          dataIndex: "address",
          render: (data) => (data ? data : "-"),
        },
        {
          title: "Created On",
          dataIndex: "created_at",
        },
      ],
    };
    const payload = { page: 1 };
    if (this.props.location?.state?.enquiryId)
      payload["id"] = this.props.location.state.enquiryId;
    this.props.fetchCustomers(payload, true);
  }
  onPageChange(page) {
    this.props.fetchCustomers({ page: page + 1, search: this.state.searchVal }, true);
  }
  handleCustomerFormModalClose() {
    this.props.fetchCustomers();
    this.setState({
      openCustomerModal: false,
      selectedCustomer: null,
    });
  }
  searchCustomer = (e) => {
    e.preventDefault();
    this.props.fetchCustomers({ page: 1, search: this.state.searchVal });
  };
  resetSearchCustomer = (e) => {
    e.preventDefault();
    this.setState(
      {
        searchVal: "",
      },
      () => this.props.fetchCustomers({ page: 1 })
    );
  };
  render() {
    const { columns, openCustomerModal, selectedCustomer } = this.state;
    const { enquiryData, enquiryLoading, currentPage, totalRow, perPage } =
      this.props;
    const buttonList = [
      <Button
        key={1}
        variant={`primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: "right" }}
        onClick={(e) => {
          this.setState({
            selectedCustomer: null,
            openCustomerModal: true,
          });
        }}
      >
        Create Customer
      </Button>,
    ];
    const switchlist = [
      <Button
        key={1}
        variant={`primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: "right" }}
        onClick={(e) => {
          this.setState({
            selectedCustomer: null,
            openCustomerModal: true,
          });
        }}
      >
        Create Customer
      </Button>,
    ];
    return (
      <div className="admin-customer">
        <Breadcrumb
          routeSegments={[{ name: "Customer List", path: "/customer" }]}
          extra={switchlist}
          patchingclass={"pad-2rem-petch"}
          switchlist_bc={buttonList}
        ></Breadcrumb>
        <Spin loading={enquiryLoading}>
          <div className="row">
            <div className="col-md-4 form-group mb-3">
              <form onSubmit={(e) => this.searchCustomer(e)}>
                <div className="input-group border rounded-pill">
                  <input
                    type="search"
                    value={this.state.searchVal}
                    onChange={(e) =>
                      this.setState({ searchVal: e.target.value })
                    }
                    placeholder="Search by Name / Phone No / Contacted By"
                    className="form-control form-control-rounded bg-none border-0"
                  />
                  <div className="input-group-append border-0">
                    {!!this.state.searchVal && (
                      <button
                        type="button"
                        onClick={this.resetSearchCustomer}
                        className="btn btn-outline-primary btn-rounded"
                      >
                        {" "}
                        <i className="fa fa-times" />{" "}
                      </button>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary btn-rounded"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Table
            dataSource={enquiryData}
            bordered={true}
            columns={columns}
            paginate={false}
          />
          <div className="sticky-footer">
            <div className="container">
              <div className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={<i className="i-Previous"></i>}
                  nextLabel={<i className="i-Next1"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={Math.round(
                    totalRow / perPage >= 1 ? totalRow / perPage : 1
                  )}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={5}
                  onPageChange={(page) => this.onPageChange(page.selected)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={currentPage - 1 ? currentPage - 1 : 0}
                />
              </div>
            </div>
          </div>
        </Spin>
        {!!openCustomerModal && (
          <AddCustomer
            show={openCustomerModal}
            data={selectedCustomer}
            onClose={() => this.handleCustomerFormModalClose()}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  enquiryData: state.customer?.data
    ? state.customer?.data.length
      ? state.customer?.data
      : Object.keys(state.customer?.data).map(
          (item) => state.customer?.data[item]
        )
    : [],
  enquiryLoading: state.customer?.loading,
  currentPage: state.customer?.current_page,
  totalRow: state.customer?.total,
  perPage: state.customer?.per_page,
});

const mapDispatchToProps = {
  fetchCustomers,
  DeleteCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCustomer);
