import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
// import ScrumBoardReducer from "./ScrumBoardReducer";
// import NotificationReducer from "./NotificationReducer";
// import EcommerceReducer from "./EcommerceReducer";
import searchProductReducer from "./SearchProductReducer";
import ProductReducer from "./ProductReducer";
import FileStorageReducer from "./FileStorageReducer";
import MiscReducer from "./MiscReducer";
import QuotationReducer from "./QuotationReducer";
import CustomerReducer from "./CustomerReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  searchProduct: searchProductReducer,
  product: ProductReducer,
  // jewellery: JewelleryReducer,
  fileStorage: FileStorageReducer,
  misc: MiscReducer,
  quotation: QuotationReducer,
  customer: CustomerReducer,
});

export default RootReducer;
