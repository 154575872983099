import React, { Component } from 'react'
import { connect } from 'react-redux'
import './AdminUsersStyle.scss';
import { Breadcrumb, SimpleCard } from '@gull'
import { fetchUsers, createNewUser, updateNewUser, fetchUserEnquiry, fetchUserOrders } from '../../../redux/actions/UserActions';
import { fetchCountries, fetchStates, fetchCities } from '../../../redux/actions/MiscActions';
import Spin from '@gull/components/Spin';
import { Formik } from "formik";
import * as yup from "yup";
import moment from 'moment';

const UserFormSchema = yup.object().shape({
    "name": yup
        .string()
        .required("Name is required"),
    "email": yup
        .string()
        .required("Email is required"),
    "mobile_no": yup
        .string()
        .required("Mobile No is required")
});

class UserDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userid: this.props?.match?.params?.userid,
            formLoading: false,
            formData: {
                "name": "",
                "email": "",
                "mobile_no": "",
            },
            columns: [

                {
                    title: 'Stone No',
                    dataIndex: 'stone_id',
                    render: (data, row) => (data)
                },
                {
                    title: 'Shape',
                    dataIndex: 'shape',
                },
                {
                    title: 'Carat',
                    dataIndex: 'carat',
                },
                {
                    title: 'Color',
                    dataIndex: 'color',
                },
                {
                    title: 'Clarity',
                    dataIndex: 'clarity',
                },
                // {
                //     title: 'Finish',
                //     dataIndex: 'Finish',
                // },
                {
                    title: 'Cut',
                    dataIndex: 'cut',
                },
                {
                    title: 'Polish',
                    dataIndex: 'polish',
                },
                {
                    title: 'Sym',
                    dataIndex: 'symmetry',
                },
                {
                    title: 'Fluo.',
                    dataIndex: 'fluo',
                },
                {
                    title: 'Lab',
                    dataIndex: 'lab',
                },
                {
                    title: 'Location',
                    dataIndex: 'location',
                },
                {
                    title: 'Discount',
                    dataIndex: 'discount',
                },
                {
                    title: 'Price/Ct.',
                    dataIndex: 'price',
                },
                {
                    title: 'Total Amt.',
                    dataIndex: 'total_amount',
                },
                {
                    title: 'Measurements',
                    dataIndex: 'measurement',
                },
                {
                    title: 'Table%',
                    dataIndex: 'table',
                },
                {
                    title: 'Depth%',
                    dataIndex: 'depth',
                },
                // {
                //     title: 'Ratio',
                //     dataIndex: 'ratio',
                // },
                {
                    title: 'Certificate No.',
                    dataIndex: 'certificate_no',
                },
            ],
            loading: false,
            openProductModal: false,
            selectedRow: []
        }
        // this.props.fetchUserEnquiry({ userid: atob(this.state.userid), isLatestRecord: true, recordLimit: 5 })
        // this.props.fetchUserOrders({ userid: atob(this.state.userid), isLatestRecord: true, recordLimit: 5 })
    }

    componentDidMount() {
        this.props.fetchCountries({})
        if (this.state.userid) {
            this.props.fetchUsers({ id: this.state.userid });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.userData !== prevProps.userData) {
            const { userData } = this.props
            let user = {};
            if (userData?.length) {
                user = userData[0];
                // this.props.fetchStates({ countryId: user.company_country.id })
                // this.props.fetchCities({ stateId: user.company_state.id })
                this.setState({
                    formData: {
                        ...this.state.formData,
                        "name": user.name ? user.name : "",
                        "email": user.email ? user.email : "",
                        "mobile_no": user.mobile_no ? user.mobile_no : "",
                    }
                })
            }
        }
    }
    handleSubmit = (value, { isSubmitting }) => {
        const that = this
        this.setState({
            formLoading: true
        }, () => {
            const payload = {
                ...that.state.formData,
                ...value,
            }

            if (that.state.userid) {
                payload['id'] = atob(that.state.userid)
                payload['updated_at'] = moment()
            }

            const resp = that.state.userid ? that.props.updateNewUser : that.props.createNewUser
            resp(payload).then(resp => {
                if (resp) {
                    that.setState({
                        formLoading: false
                    }, () => {
                        that.props.history.push('/users')
                    })
                } else {
                    that.setState({
                        formLoading: false
                    })
                }
            })
                .catch(e => {
                    console.error(e);
                    that.setState({
                        formLoading: false
                    })
                });
        })
    };

    onUploadFinish = (url, type) => {
        let key = "profile_img";
        switch (type) {
            case "profile": key = "profile_img"; break;
            case "company": key = "company_profile_img"; break;
            default: key = "profile_img";
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [key]: url,
            }
        })
    }
    handleFormValueChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, () => {
            console.log(this.props.errors);
            if (e.target.name === 'company_country_id') {
                this.props.fetchStates({ countryId: e.target.value })
            }
            if (e.target.name === 'company_state_id') {
                this.props.fetchCities({ stateId: e.target.value })
            }
        })

    }

    toggleProductModal = (row) => {
        this.setState({
            openProductModal: !this.state.openProductModal,
            selectedRow: row
        })
    }
    render() {
        const {  loading } = this.props
        const { formLoading } = this.state
        return (
            <div className="user-details">
                <Breadcrumb
                    routeSegments={[
                        { name: `User Detail`, path: "/" }
                    ]}
                    isBackButton={true}
                ></Breadcrumb>
                <Spin loading={(loading | formLoading)}>
                    <Formik
                        enableReinitialize
                        initialValues={this.state.formData}
                        validationSchema={UserFormSchema}
                        onSubmit={this.handleSubmit}
                        handleChange={this.handleFormValueChange}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setValues,
                            isSubmitting
                        }) => {
                            return (
                                <form onSubmit={e => { console.log(errors); handleSubmit(e) }}>
                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <SimpleCard title="Personal Info" className="mb-3">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Name</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="name"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.name}
                                                                        placeholder="Name"
                                                                        readOnly
                                                                    />
                                                                    {(errors.name && touched.name) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.name}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Email</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="email"
                                                                        name="email"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}
                                                                        placeholder="Email"
                                                                        readOnly
                                                                    />
                                                                    {(errors.email && touched.email) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.email}
                                                                        </div>
                                                                    )}
                                                                </div></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Mobile No</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="mobile_no"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.mobile_no}
                                                                        placeholder="Mobile No"
                                                                        readOnly
                                                                    />
                                                                    {(errors.mobile_no && touched.mobile_no) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.mobile_no}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                        </div>
                                       
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userList?.data,
    loading: state.user.loading,
    enquiryData: state.user.userEnquiryData,
    enquiryLoading: state.user.userEnquiryLoading,
    ordersData: state.user.userOrdersData?.data,
    ordersLoading: state.user?.userOrdersLoading,
    user: state.user,
    countryloading: state.misc?.countryloading,
    countryList: state.misc?.countryList,
    stateloading: state.misc?.stateloading,
    stateList: state.misc?.stateList,
    cityloading: state.misc?.cityloading,
    cityList: state.misc?.cityList,
})

const mapDispatchToProps = {
    fetchUsers,
    createNewUser,
    updateNewUser,
    fetchCountries,
    fetchStates,
    fetchCities,
    fetchUserEnquiry,
    fetchUserOrders
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);