const { apiAdapter } = require("./ApiAdapter");

const getFeaturedProducts = () => {
  return apiAdapter({
    url: "product/featured",
    method: "GET",
  });
};

const getNewProducts = () => {
  return apiAdapter({
    url: "product/new",
    method: "GET",
  });
};

const getProductsById = (id) => {
  return apiAdapter({
    url: `product?id=${id}`,
    method: "GET",
  });
};

const getSearchedProductsV2 = (page, payload = {}) => {
  return apiAdapter({
    url: "/v2/product/search",
    method: "POST",
    data: payload,
    params: { page },
  });
};
const getProductsWithFiltersV2 = (filters = {}) => {
  return apiAdapter({
    url: "/v2/product",
    method: "GET",
    params: filters,
  });
};
const getProductBrandCodeList = (filters = {}) => {
  return apiAdapter({
    url: "v2/product/brand-code/list",
    method: "GET",
    params: filters,
  });
};
const createProductV2 = (data) => {
  return apiAdapter({
    url: "/v2/product/create",
    method: "POST",
    data,
  });
};
const updateProductV2 = (data) => {
  return apiAdapter({
    url: "/v2/product/update",
    method: "PUT",
    data,
  });
};
const deleteProductsV2 = (data) => {
  return apiAdapter({
    url: "v2/product/delete",
    method: "PUT",
    data,
  });
};

const getRecommendedProducts = () => {
  return apiAdapter({
    url: "user/recommend/products",
    method: "GET",
  });
};

const createRecommendedProducts = (data) => {
  return apiAdapter({
    url: "user/recommend/products",
    method: "POST",
    data,
  });
};

const getSearchedProducts = (page, payload) => {
  return apiAdapter({
    url: "product/search",
    method: "POST",
    data: payload,
    params: { page },
  });
};

const createNewEnquiry = (payload) => {
  return apiAdapter({
    url: "product/enquiry",
    method: "POST",
    data: payload,
  });
};

const createNewPlaceOrder = (payload) => {
  return apiAdapter({
    url: "product/order",
    method: "POST",
    data: payload,
  });
};

const updatePlaceOrder = (payload) => {
  return apiAdapter({
    url: "product/order/update",
    method: "PUT",
    data: payload,
  });
};

const getLayouts = (page, id = null) => {
  const params = {
    page,
  };
  if (id) params.id = id;
  return apiAdapter({
    url: "layouts",
    method: "GET",
    params,
  });
};

const createProduct = (data) => {
  return apiAdapter({
    url: "product/create",
    method: "POST",
    data,
  });
};

const updateProduct = (data) => {
  return apiAdapter({
    url: "product/update",
    method: "PUT",
    data,
  });
};

const deleteProducts = (data) => {
  return apiAdapter({
    url: "product/delete",
    method: "PUT",
    data,
  });
};

const createLayout = (data) => {
  return apiAdapter({
    url: "layouts/create",
    method: "POST",
    data,
  });
};

const updateLayout = (data) => {
  return apiAdapter({
    url: "layouts/update",
    method: "PUT",
    data,
  });
};

const deleteLayout = (data) => {
  return apiAdapter({
    url: "layouts/delete",
    method: "DELETE",
    data,
  });
};

const bulkProductUpload = (data) => {
  return apiAdapter({
    url: "file/product/import",
    method: "POST",
    data,
  });
};

const exportProduct = (data) => {
  return apiAdapter({
    url: "v2/product/export/excel",
    method: "POST",
    data,
  });
};

const bulkProductImagesUpload = (data) => {
  return apiAdapter({
    url: "file/product/images",
    method: "POST",
    data,
  });
};

module.exports = {
  getFeaturedProducts,
  getRecommendedProducts,
  getSearchedProducts,
  getNewProducts,
  createNewEnquiry,
  createNewPlaceOrder,
  updatePlaceOrder,
  getProductsById,
  getLayouts,
  createProduct,
  updateProduct,
  createLayout,
  updateLayout,
  bulkProductUpload,
  bulkProductImagesUpload,
  createRecommendedProducts,
  exportProduct,
  deleteProducts,
  deleteLayout,
  getProductsWithFiltersV2,
  getProductBrandCodeList,
  getSearchedProductsV2,
  createProductV2,
  updateProductV2,
  deleteProductsV2
};
