import Spin from "@gull/components/Spin";
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import {
  CreateCustomer,
  UpdateCustomer,
} from "app/redux/actions/CustomerActions";
import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as yup from "yup";

const FormSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  phone: yup.string().nullable(),
  address: yup.string().nullable(),
  contacted_by: yup.string().nullable(),
});

function AddCustomer({ show, onClose, CreateCustomer, UpdateCustomer, data }) {
  const [formData] = useState({
    name: "",
    phone: "",
    address: "",
    contacted_by: "",
    ...(!!data ? data : {}),
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = (value, { isSubmitting }) => {
    setLoading(true);
    const apiCaller = !!data ? UpdateCustomer : CreateCustomer;
    apiCaller(value)
      .then((resp) => {
        setLoading(false);
        onClose(resp);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <AppModal
      show={show}
      title={`${!!data ? "Update" : "Add"} Customer`}
      size="md"
      onHide={() => onClose()}
    >
      <Spin loading={loading}>
        <Formik
          enableReinitialize={true}
          validateOnMount
          initialValues={formData}
          validationSchema={FormSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <b>Customer Name</b>
                    <div className="form-group">
                      <input
                        className="form-control position-relative"
                        type="text"
                        name="name"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.name}
                        placeholder="Enter Customer Name"
                      />
                      {errors.name && touched.name && (
                        <div className="text-danger mt-1 ml-1">
                          {errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b>Contacter Name</b>
                    <div className="form-group">
                      <input
                        className="form-control position-relative"
                        type="text"
                        name="contacted_by"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.contacted_by}
                        placeholder="Enter Customer Name"
                      />
                      {errors.contacted_by && touched.contacted_by && (
                        <div className="text-danger mt-1 ml-1">
                          {errors.contacted_by}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b>Customer Phone</b>
                    <div className="form-group">
                      <input
                        className="form-control position-relative"
                        type="text"
                        name="phone"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.phone}
                        placeholder="Enter Customer Name"
                      />
                      {errors.phone && touched.phone && (
                        <div className="text-danger mt-1 ml-1">
                          {errors.phone}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b>Address</b>
                    <div className="form-group">
                      <input
                        className="form-control position-relative"
                        type="text"
                        name="address"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.address}
                        placeholder="Enter Customer Name"
                      />
                      {errors.address && touched.address && (
                        <div className="text-danger mt-1 ml-1">
                          {errors.address}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Modal.Footer>
                  <Button onClick={onClose} variant="outline-danger">
                    Cancel
                  </Button>
                  <Button type="submit" disabled={!isValid}>
                    Save
                  </Button>
                </Modal.Footer>
              </form>
            );
          }}
        </Formik>
      </Spin>
    </AppModal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  CreateCustomer,
  UpdateCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
