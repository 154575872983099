import React, { Component } from "react";
import "./style.scss";
import { Breadcrumb } from "@gull";
import { Badge } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getSavedSearch,
  getRecentSearch,
} from "../../../redux/actions/SearchProductAction";
import { AdminDashboardStats } from "../../../redux/actions/MiscActions";
import moment from "moment";
import Spin from "@gull/components/Spin";
import { fetchFeaturedProduct } from "../../../redux/actions/ProductActions";
import BulkProductUpload from "app/GullLayout/SharedComponents/bulk-upload/BulkProductUpload";
import BulkProductImageUpload from "app/GullLayout/SharedComponents/bulk-upload/BulkProductImageUpload";
import { getNewletters } from "app/services/UserService";
import ReactPaginate from "react-paginate";

class Dashboard extends Component {
  state = {
    windowWidth: window.innerWidth,
    cardList: [
      {
        icon: "total-enquiries-icon",
        subtitle: "Total Enquiries",
        title: "NA",
        onClick: (e) => this.props.history.push("/enquiries"),
      },
      {
        icon: "new-diamonds-icon",
        subtitle: "Total Products",
        title: "NA",
        onClick: (e) => this.props.history.push("/product"),
      },
      {
        icon: "recommended-icond",
        subtitle: "Total Users",
        title: "NA",
        onClick: (e) => this.props.history.push("/users"),
      },
      {
        icon: "fa-file",
        usei: true,
        subtitle: "Total Quatations",
        title: "NA",
        onClick: (e) => this.props.history.push("/quotations"),
      },
    ],
    openBankModal: false,
    openEnquiryModal: false,
    enquiryPayload: {
      remark: "",
    },
    newslettersLoading: false,
    newsletters: {
      data: [],
    },
  };
  componentDidMount() {
    this.props.AdminDashboardStats();
    this.fetchNewsletters(1);
    // this.props.fetchFeaturedProduct({ featured: true, limit: 5 })
    // if (!this.props.searchList) {
    //   this.props.getSavedSearch()
    // }
    // if (!this.props.recentList) {
    //   this.props.getRecentSearch()
    // }
  }

  componentDidUpdate(prevProps) {
    if (this.props.adminStats !== prevProps.adminStats) {
      const { adminStats } = this.props;
      this.setState({
        cardList: [
          {
            icon: "i-Telephone",
            usei:true,
            subtitle: "Total Enquiries",
            title: adminStats.totalEnquiries ?? "NA",
            onClick: (e) => this.props.history.push("/enquiries"),
          },
          {
            icon: "i-Tag",
            usei: true,
            subtitle: "Total Products",
            title: adminStats.productCount ?? "NA",
            onClick: (e) => this.props.history.push("/product"),
          },
          {
            icon: "i-Boy",
            usei: true,
            subtitle: "Total Users",
            title: adminStats.usersCount ?? "NA",
            onClick: (e) => this.props.history.push("/users"),
          },
          {
            icon: "i-File-Horizontal-Text",
            usei: true,
            subtitle: "Total Quatations",
            title: adminStats.quotationCount ?? "NA",
            onClick: (e) => this.props.history.push("/quotations"),
          },
        ],
      });
    }
  }

  fetchNewsletters = (page) => {
    this.setState(
      {
        newslettersLoading: true,
      },
      () => {
        getNewletters(page)
          .then((resp) => {
            this.setState({
              newsletters: resp?.data?.data,
              newslettersLoading: false,
            });
          })
          .catch((e) => {
            this.setState({
              newslettersLoading: false,
            });
          });
      }
    );
  };

  handlePageClick = (data) => {
    let pageNumber = data.selected;
    let offset = Math.ceil(pageNumber * this.props.perPage);
    this.setState({ offset: offset }, () => {
      console.log(data);
    });
  };

  getUserStatusClass = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "delivered":
        return "badge-success";
      case "inactive":
        return "badge-warning";
      case "not delivered":
        return "badge-warning";
      case "pending":
        return "badge-primary";
      default:
        break;
    }
  };

  getStatusTextColor = (status) => {
    switch (status) {
      case "In Stock":
        return "text-success";
      case "Low Stock":
        return "text-warning";
      case "Out of Stock":
        return "text-danger";
      default:
        return "text-primary";
    }
  };

  openBankModal = () => {
    this.setState({
      openBankModal: !this.state.openBankModal,
    });
  };

  openEnquiryModal = () => {
    this.setState({
      openEnquiryModal: true,
    });
  };

  handleEnquirySubmit = (value, { isSubmitting }) => {
    const payload = {
      requirements: value.remark,
    };
    this.setState(
      {
        demandLoader: true,
      },
      () => this.props.createProductEnquiry(payload)
    );
  };

  getOrderStatus = (row) => {
    let badge = "";
    switch (Number(row.status)) {
      case 1:
        badge = (
          <Badge key={1} className={`badge-warning text-white p-2`}>
            {"PENDING"}
          </Badge>
        );
        break;
      case 2:
        badge = (
          <Badge key={2} className={`badge-primary p-2`}>
            {"ACCEPTED"}
          </Badge>
        );
        break;
      case 3:
        badge = (
          <Badge key={0} className={`badge-danger p-2`}>
            {"REJCTED"}
          </Badge>
        );
        break;
      case 4:
        badge = (
          <Badge key={4} className={`badge-danger p-2`}>
            {"CANCELED"}
          </Badge>
        );
        break;
      case 0:
        badge = (
          <Badge key={0} className={`badge-success p-2`}>
            {"COMPLETED"}
          </Badge>
        );
        break;
      default:
        badge = (
          <Badge key={1} className={`badge-warning text-white p-2`}>
            {"PENDING"}
          </Badge>
        );
        break;
    }
    if (Number(row.total_pending) === Number(row.total_paid))
      badge = (
        <Badge key={0} className={`badge-success p-2`}>
          {"COMPLETED"}
        </Badge>
      );
    return badge;
  };

  onPageChange = (page) => {
    this.fetchNewsletters(page + 1);
  };

  render() {
    let {
      cardList = [],
      newslettersLoading,
      newsletters,
      // lastMonthSummery = [],
      // topAuthorList = [],
      // newUserList = [],
      // topProductList = []
    } = this.state;
    const { user, adminStatsloading, adminStats } = this.props;
    const buttonList = [<BulkProductUpload />, <BulkProductImageUpload />];
    const buttonListmerge = [
      <BulkProductUpload className={"parchdisplay"} />,
      <BulkProductImageUpload className={"parchdisplay"} />,
    ];
    let switchlist = [];
    if (this.state.windowWidth > 600) {
      switchlist = [...switchlist, ...buttonListmerge];
    }
    return (
      <div className="report dashboard">
        <Breadcrumb
          routeSegments={[{ name: "Dashboard", path: "/dashboard" }]}
          extra={switchlist}
          patchingclass={"pad-2rem-petch"}
          switchlist_bc={buttonList}
        ></Breadcrumb>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-4 greet-card">
                  <div className="card-body">
                    <div className="card-text">
                      <p className="greeting-text">Welcome Back,</p>
                      <div className="row">
                        <div className="col-md-6 h4 login-time">
                          {user.name}
                        </div>
                        <div className="col-md-6 text-right login-time">
                          Last logged in :{" "}
                          {moment(this.props.user.last_login_date).format(
                            "DD MMM, YYYY hh:mm A"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cardList.map((card, index) => (
                <div key={index} className="col-md-6">
                  <Spin loading={adminStatsloading}>
                    <div
                      className="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
                      style={{ cursor: "pointer" }}
                      {...{ onClick: card.onClick }}
                    >
                      <div className="card-body text-center">
                        <div className="imagecard">
                          {card.usei ? (
                            <i className={card.icon} style={{fontSize: 53, color: 'grey'}}></i>
                          ) : (
                            <img
                              className="nav-icon"
                              alt="loading"
                              style={{width: 50}}
                              src={`${process.env.PUBLIC_URL}/assets/images/dashboard-icon/${card.icon}.svg`}
                            />
                          )}
                        </div>
                        <div className="ml-5">
                          <p className="text-muted mt-2 mb-0 text-capitalize">
                            {card.subtitle}
                          </p>
                          <p className="lead text-primary text-24 mb-2 text-capitalize">
                            {card.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              ))}
              {/* <div className="col-md-12">
                <SimpleCard>
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="d-flex px-2">
                      <Nav.Item className="mr-2 flex-grow-1 text-center">
                        <Nav.Link eventKey="first">Today's Enquries</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="mr-2 flex-grow-1 text-center">
                        
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </SimpleCard>
              </div> */}
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <Spin loading={adminStatsloading}>
                  <div className="card mb-4">
                    <div className="card-header featured-header">
                      Today's Enquries
                    </div>
                    <div className="card-body">
                      {adminStats?.todayEnquiries?.length ? (
                        adminStats?.todayEnquiries.map((item, index) => (
                          <div
                            onClick={(e) =>
                              this.props.history.push("/enquiries", {
                                enquiryId: item.id,
                              })
                            }
                            key={index}
                            className="d-flex border justify-content-between p-3 mb-3"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="flex-grow-1 w-50">
                              <span className="text-small">
                                <b>{item.name?.length ? item.name : ""}</b>
                                {" - "}
                                {item.email}
                                <br></br>
                                {item.message}
                              </span>
                            </div>
                            <div className="flex-grow-1">
                              <span className="text-small">
                                {moment(item.updated_at).format("DD-MMM-YYYY")}
                              </span>
                            </div>

                            <div className="flex-grow-1">
                              <span className="text-small">
                                {moment(item.updated_at).format("hh : ss A")}
                              </span>
                            </div>
                            {/* <div className="flex-grow-1">
                                  <span className="text-small">
                                    <i className="i-Remove" />
                                  </span>
                                </div> */}
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className="text-center">
                            <svg
                              width="184"
                              height="152"
                              viewBox="0 0 184 152"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="none" fillRule="evenodd">
                                <g transform="translate(24 31.67)">
                                  <ellipse
                                    fillOpacity=".8"
                                    fill="#F5F5F7"
                                    cx="67.797"
                                    cy="106.89"
                                    rx="67.797"
                                    ry="12.668"
                                  ></ellipse>
                                  <path
                                    d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                    fill="#AEB8C2"
                                  ></path>
                                  <path
                                    d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                                    fill="url(#linearGradient-1)"
                                    transform="translate(13.56)"
                                  ></path>
                                  <path
                                    d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                    fill="#F5F5F7"
                                  ></path>
                                  <path
                                    d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                    fill="#DCE0E6"
                                  ></path>
                                </g>
                                <path
                                  d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                                  fill="#DCE0E6"
                                ></path>
                                <g
                                  transform="translate(149.65 15.383)"
                                  fill="#FFF"
                                >
                                  <ellipse
                                    cx="20.654"
                                    cy="3.167"
                                    rx="2.849"
                                    ry="2.815"
                                  ></ellipse>
                                  <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <Spin loading={newslettersLoading}>
                  <div className="card mb-4">
                    <div className="card-header featured-header">
                      Newsletters List
                    </div>
                    <div className="card-body">
                      {newsletters?.data?.length ? (
                        newsletters?.data.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex border justify-content-between p-3 mb-3"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="flex-grow-1">
                              <span className="text-small">{item.name}</span>
                            </div>
                            <div className="flex-grow-1">
                              <span className="text-small">{item.email}</span>
                            </div>
                            <div
                              className="flex-grow-1"
                              style={{ textAlign: "right" }}
                            >
                              <span className="text-small">
                                {moment(item.updated_at).format(
                                  "DD-MMM-YYYY hh : ss A"
                                )}
                              </span>
                            </div>
                            {/* <div className="flex-grow-1">
                                  <span className="text-small">
                                    <i className="i-Remove" />
                                  </span>
                                </div> */}
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className="text-center">
                            <svg
                              width="184"
                              height="152"
                              viewBox="0 0 184 152"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="none" fillRule="evenodd">
                                <g transform="translate(24 31.67)">
                                  <ellipse
                                    fillOpacity=".8"
                                    fill="#F5F5F7"
                                    cx="67.797"
                                    cy="106.89"
                                    rx="67.797"
                                    ry="12.668"
                                  ></ellipse>
                                  <path
                                    d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                    fill="#AEB8C2"
                                  ></path>
                                  <path
                                    d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                                    fill="url(#linearGradient-1)"
                                    transform="translate(13.56)"
                                  ></path>
                                  <path
                                    d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                    fill="#F5F5F7"
                                  ></path>
                                  <path
                                    d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                    fill="#DCE0E6"
                                  ></path>
                                </g>
                                <path
                                  d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                                  fill="#DCE0E6"
                                ></path>
                                <g
                                  transform="translate(149.65 15.383)"
                                  fill="#FFF"
                                >
                                  <ellipse
                                    cx="20.654"
                                    cy="3.167"
                                    rx="2.849"
                                    ry="2.815"
                                  ></ellipse>
                                  <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      )}
                      <div className="container">
                        <div className="d-flex justify-content-center mt-3 mb-3">
                          <ReactPaginate
                            previousLabel={<i className="i-Previous"></i>}
                            nextLabel={<i className="i-Next1"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(
                              newsletters?.total / newsletters?.per_page >= 1
                                ? newsletters?.total / newsletters?.per_page
                                : 1
                            )}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={(page) =>
                              this.onPageChange(page.selected)
                            }
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            forcePage={
                              newsletters?.current_page - 1
                                ? newsletters?.current_page - 1
                                : 0
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  searchList: state.searchProduct.savedSearches,
  savedSeachloading: state.searchProduct.savedSeachloading,
  recentList: state.searchProduct.recentSearches,
  recentSeachloading: state.searchProduct.recentSeachloading,
  settings: state.layout.settings,
  adminStats: state.misc?.adminStats,
  adminStatsloading: state.misc?.adminStatsloading,
  featuredLoading: state.product.loading,
  featuredProductList: state.product.data,
});
export default connect(mapStateToProps, {
  getSavedSearch,
  AdminDashboardStats,
  getRecentSearch,
  fetchFeaturedProduct,
})(Dashboard);
