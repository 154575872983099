import React from "react";
import { Card } from "react-bootstrap";

const SimpleCard = ({ title, children, className, subtitle, extra }) => {
  return (
    <Card className={className}>
      <Card.Body>
        {(title || subtitle) && (
          <div className="simple-card-title pb-2">
            {title && <h3 className="text-capitalize mb-1" style={{ float: extra ? 'left' : 'unset' }}>{title}</h3>}
            {subtitle && <h6 className="text-mute">{subtitle}</h6>}
            {extra && <div className="text-right"> {extra} </div>}
          </div>
        )}
        {children}
      </Card.Body>
    </Card>
  );
};

export default SimpleCard;
