import React, { Component } from "react";
import { Breadcrumb } from "@gull";
import Spin from "@gull/components/Spin";
import "./AdminQuatationSummaryStyle.scss";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { fetchQuotations } from "../../../redux/actions/QuotationActions";
import { withRouter } from "react-router-dom";
import SummaryTable from "./components/Summarytable";
import { fetchCustomerListWithQuotation } from "app/redux/actions/CustomerActions";

const CheckboxOption = ({ ...props }) => (
  <components.Option {...props}>
    <input id={props.label} type="checkbox" checked={props.isSelected} />
    <label htmlFor={props.label}>{props.label}</label>
  </components.Option>
);

class AdminQuotationSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedQuotationList: [],
      quotationList: [],
      selectedCustomer: undefined,
      summaryData: {},
    };
  }
  asyncSelectCustomerOptions = debounce((inputValue, cb) => {
    if (!inputValue) {
      cb([]);
      return false;
    }
    this.props
      .fetchCustomerListWithQuotation({
        name: inputValue,
      })
      .then((resp) => {
        cb(
          !!inputValue
            ? resp?.map((item) => ({
                label: item?.name,
                value: item?.quotations,
              })) || []
            : []
        );
      });
  }, 300);
  asyncOnCustomerChange(selectedOption, actions) {
    const { action, removedValue } = actions;
    console.log("action", actions);
    switch (action) {
      case "select-option":
        if (!!selectedOption) {
          this.setState({
            selectedCustomer: selectedOption,
            selectedQuotationList: [],
            summaryData: this.quotationProcessor([]),
            quotationList:
              selectedOption?.value?.map((item) => ({
                label: item.code,
                value: item?.id,
              })) ?? [],
          });
        }
        break;
      case "remove-value":
        this.setState({
          selectedQuotationList: this.state?.selectedQuotationList?.filter(
            (item) => item?.id !== removedValue.value
          ),
        });
        break;
      case "clear":
        this.setState({
          selectedQuotationList: [],
        });
        break;
      default:
        break;
    }
  }
  asyncSelectOptions = debounce((inputValue, cb) => {
    const list = !!inputValue
      ? this.state.quotationList?.filter((i) =>
          i.label?.toLowerCase().includes(inputValue?.toLowerCase())
        )
      : [];
    this.setState({
      quotationList: list,
    });
    cb(list);
  }, 300);
  asyncOnChange(selectedOption, actions) {
    const { action, removedValue } = actions;
    console.log("action", actions);
    switch (action) {
      case "select-option":
        if (!!selectedOption?.length) {
          this.props
            .fetchQuotations({
              ids: btoa(selectedOption?.map((item) => item?.value).join(",")),
            })
            .then((resp) => {
              this.setState({
                selectedQuotationList: resp?.data?.data?.data,
                summaryData: this.quotationProcessor(resp?.data?.data?.data),
              });
            });
        }
        break;
      case "remove-value":
        const newValues = this.state?.selectedQuotationList?.filter(
          (item) => item?.id !== removedValue.value
        );
        this.setState({
          selectedQuotationList: newValues,
          summaryData: this.quotationProcessor(newValues),
        });
        break;
      case "clear":
        this.setState({
          selectedQuotationList: [],
          summaryData: this.quotationProcessor([]),
        });
        break;
      default:
        break;
    }
  }
  groupProductItemsByBrand(products) {
    const brandGroups = {};

    products.forEach((product) => {
      const { brand, quotationCode } = product;

      if (!brandGroups[brand]) {
        brandGroups[brand] = {
          brand,
          productItems: [],
        };
      }

      brandGroups[brand].productItems.push(
        ...product.productItems?.map((i) => ({ ...i, quotationCode }))
      );
    });

    const nameGroups = {};
    Object.values(brandGroups).forEach((product) => {
      const { brand, productItems } = product;
      if (!nameGroups[brand]) {
        nameGroups[brand] = {
          brand,
          productName: {},
        };
      }

      productItems.forEach((productItem) => {
        const { name } = productItem?.product;

        const quotationCode = productItem?.quotationCode;
        if (!nameGroups[brand]["productName"][name]) {
          nameGroups[brand]["productName"][name] = {
            name,
          };
        }
        if (!nameGroups[brand]["productName"][name][quotationCode]) {
          nameGroups[brand]["productName"][name][quotationCode] = {
            quotationCode,
            productItems: [],
            totalSalesAmount: 0,
            totalPurchaseAmount: 0,
          };
        }
        console.log(
          "groupProductItemsByBrand quotationCode",
          brand,
          name,
          quotationCode
        );
        nameGroups[brand]["productName"][name][quotationCode].productItems.push(
          productItem
        );
        nameGroups[brand]["productName"][name][quotationCode][
          "totalSalesAmount"
        ] += Number(productItem?.sales_total_amount, 0);
        nameGroups[brand]["productName"][name][quotationCode][
          "totalPurchaseAmount"
        ] += Number(productItem?.purchase_total_amount, 0);
      });
      //   brandGroups[brand][name]?.push(...product.productItems);
    });

    return nameGroups;
  }

  quotationProcessor(quotations) {
    let mainGroup = {};
    // const noUse = quotations?.forEach((item) => {
    //   mainGroup[item.code] = {
    //     code: item.code,
    //     brands: this.groupProductItemsByBrand(
    //       item?.products?.map((item) => ({ ...item, quotationCode: item.code }))
    //     ),
    //   };
    // });

    mainGroup = this.groupProductItemsByBrand(
      quotations
        ?.map((item) =>
          item.products?.map((i) => ({ ...i, quotationCode: item.code }))
        )
        .flat()
    );
    console.log("quotations", mainGroup);

    return mainGroup;
  }
  render() {
    const {
      quotationList,
      selectedCustomer,
      selectedQuotationList,
      summaryData,
    } = this.state;
    const { loading } = this.props;
    const buttonList = [];
    const buttonListmerge = [];
    let switchlist = [];
    if (this.state.windowWidth > 600) {
      switchlist = [...switchlist, ...buttonListmerge];
    }
    return (
      <div className="admin-users">
        <Breadcrumb
          routeSegments={[
            { name: "Quotation Summary", path: "/quatation-summary" },
          ]}
          extra={switchlist}
          patchingclass={"pad-2rem-petch"}
          switchlist_bc={buttonList}
        ></Breadcrumb>
        <Spin loading={loading}>
          <div className="row pad-2rem-petch">
            <div className="col-md-12 form-group mb-3">
              <AsyncSelect
                styles={{
                  menu: (style) => ({
                    ...style,
                  }),
                }}
                value={selectedCustomer}
                placeholder={"Search Customer Name"}
                cacheOptions
                defaultOptions
                onChange={(newValue, actionMeta) => {
                  this.asyncOnCustomerChange(newValue, actionMeta);
                }}
                loadOptions={this.asyncSelectCustomerOptions}
              />
            </div>
            <div className="col-md-12 form-group mb-3">
              <Select
                isClearable
                isSearchable
                options={quotationList}
                filterOption={(item, input) => {
                  if (input) {
                    return item?.label
                      ?.toLowerCase()
                      .includes(input?.toLowerCase());
                  }
                  return true;
                }}
                isMulti
                closeMenuOnSelect={false}
                components={{ Option: CheckboxOption }}
                placeholder={"Search Quotation No"}
                onChange={(newValue, actionMeta) => {
                  this.asyncOnChange(newValue, actionMeta);
                }}
              />
            </div>
            <div className="col-md-12 form-group mb-3">
              {!!selectedQuotationList?.length ? (
                <SummaryTable
                  data={summaryData}
                  quotationCodes={selectedQuotationList?.map(
                    (item) => item.code
                  )}
                />
              ) : (
                <div className="w-100">
                  <div className="text-center m-5">
                    <svg
                      width="184"
                      height="152"
                      viewBox="0 0 184 152"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                          <ellipse
                            fillOpacity=".8"
                            fill="#F5F5F7"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                          ></ellipse>
                          <path
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            fill="#AEB8C2"
                          ></path>
                          <path
                            d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                            fill="url(#linearGradient-1)"
                            transform="translate(13.56)"
                          ></path>
                          <path
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            fill="#F5F5F7"
                          ></path>
                          <path
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            fill="#DCE0E6"
                          ></path>
                        </g>
                        <path
                          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                          fill="#DCE0E6"
                        ></path>
                        <g transform="translate(149.65 15.383)" fill="#FFF">
                          <ellipse
                            cx="20.654"
                            cy="3.167"
                            rx="2.849"
                            ry="2.815"
                          ></ellipse>
                          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                        </g>
                      </g>
                    </svg>
                    <div className="mt-3">
                      Search some quotation Code to Generate Summary
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.quotation.loading,
  quotationList: state.quotation?.data,
  currentPage: state.quotation?.current_page,
  totalRow: state.quotation?.total,
  perPage: state.quotation?.per_page,
});
const mapDispatchToProps = {
  fetchQuotations,
  fetchCustomerListWithQuotation,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminQuotationSummary));
