import React from "react";

function QuotationTableHeadRow({ columns }) {
  const style = {
    width: "auto",
    whiteSpace: "pre-wrap",
  };
  return (
    <thead className="thead-dark">
      <tr>
        {columns &&
          columns.map((col, index) => {
            let colStyle = { ...style };
            if (col.width) {
              colStyle = {
                width: col.width,
              };
            }
            return (
              <th key={index} style={colStyle} scope="col">
                {col.title}{" "}
              </th>
            );
          })}
      </tr>
    </thead>
  );
}

export default React.memo(QuotationTableHeadRow);
