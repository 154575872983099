import React, { useMemo } from "react";

function QuotationSummaryStatsRow({ productList, manifoldProductList }) {
  const totalStats = useMemo(() => {
    const totalStats = {
      qty: 0,
      totalNetAmount: 0,
      totalAmount: 0,
      totalPNetAmount: 0,
      totalPAmount: 0,
    };
    const nouse = productList.forEach((prod) => {
      const nouse = prod?.productItems?.forEach((productItem) => {
        if (productItem?.action !== "delete") {
          totalStats.qty += Number(productItem.quantity);
          totalStats.totalAmount += Number(productItem.sales_total_amount);
          totalStats.totalNetAmount += Number(productItem.sales_net_amount);
          totalStats.totalPAmount += Number(productItem.purchase_total_amount);
          totalStats.totalPNetAmount += Number(productItem.purchase_net_amount);
        }
      });
      console.log(nouse);
    });
    console.log(nouse);

    const nouse2 = manifoldProductList?.forEach((productItem) => {
      if (productItem?.action !== "delete") {
        totalStats.qty += Number(productItem.quantity);
        totalStats.totalAmount += Number(productItem.sales_total_amount);
        totalStats.totalNetAmount += Number(productItem.sales_net_amount);
        totalStats.totalPAmount += Number(productItem.purchase_total_amount);
        totalStats.totalPNetAmount += Number(productItem.purchase_net_amount);
      }
    });
    console.log(nouse2);

    return totalStats;
  }, [productList, manifoldProductList]);
  return (
    <tr
      id={2}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.075)",
      }}
    >
      <td></td>
      <td></td>
      <td className="float-right">
        <input
          className="form-control position-relative"
          type="text"
          name="Total"
          value={"Total"}
          readOnly
          style={{
            backgroundColor: "transparent",
            border: "none",
            fontWeight: "bold",
            width: "35%",
          }}
        />
      </td>
      <td>
        <div className="form-group">
          <div
            className="form-control position-relative"
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: "bold",
            }}
          >
            {totalStats.qty}
          </div>
        </div>
      </td>
      <td></td>
      <td></td>
      <td>
        <div className="form-group">
          <div
            className="form-control position-relative"
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: "bold",
            }}
          >
            {totalStats.totalNetAmount}
          </div>
        </div>
      </td>
      <td>
        <div className="form-group">
          <div
            className="form-control position-relative"
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: "bold",
            }}
          >
            {totalStats.totalAmount}
          </div>
        </div>
      </td>
      <td></td>
      <td>
        <div className="form-group">
          <div
            className="form-control position-relative"
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: "bold",
            }}
          >
            {totalStats.totalPNetAmount}
          </div>
        </div>
      </td>
      <td>
        <div className="form-group">
          <div
            className="form-control position-relative"
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: "bold",
            }}
          >
            {totalStats.totalPAmount}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default React.memo(QuotationSummaryStatsRow);
