import {
  GET_QUOTATION_BEGIN,
  GET_QUOTATION_SUCESS,
  GET_QUOTATION_FAILED,
  CREATE_QUOTATION_BEGIN,
  CREATE_QUOTATION_SUCESS,
  CREATE_QUOTATION_FAILED,
} from "../actions/QuotationActions";

const initialState = {
  loading: false,
  data: [],
};

const QuotationReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_QUOTATION_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_QUOTATION_SUCESS: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    }
    case GET_QUOTATION_FAILED: {
      return {
        ...state,
        loading: false,
        data: [],
        quotationError: action.payload,
      };
    }
    case CREATE_QUOTATION_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_QUOTATION_SUCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CREATE_QUOTATION_FAILED: {
      return {
        ...state,
        loading: false,
        quotationError: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default QuotationReducer;
