import { GenerateSeachForm, GetSavedSearch, GetRecentSearch, PostSavedSearch } from '../../services/SearchProductService';
import { NotificationManager } from 'react-notifications';

export const GET_SEARCH_DIAMOND_FORM_BEGIN = 'GET_SEARCH_DIAMOND_FORM_BEGIN';
export const GET_SEARCH_DIAMOND_FORM_SUCESS = 'GET_SEARCH_DIAMOND_FORM_SUCESS';
export const GET_SEARCH_DIAMOND_FORM_FAILED = 'GET_SEARCH_DIAMOND_FORM_FAILED';

export const GET_SAVED_SEARCH_BEGIN = 'GET_SAVED_SEARCH_BEGIN';
export const GET_SAVED_SEARCH_SUCESS = 'GET_SAVED_SEARCH_SUCESS';
export const GET_SAVED_SEARCH_FAILED = 'GET_SAVED_SEARCH_FAILED';

export const GET_RECENT_SEARCH_BEGIN = 'GET_RECENT_SEARCH_BEGIN';
export const GET_RECENT_SEARCH_SUCESS = 'GET_RECENT_SEARCH_SUCESS';
export const GET_RECENT_SEARCH_FAILED = 'GET_RECENT_SEARCH_FAILED';

export const getSearchProductForm = (type) => dispatch => {
    dispatch({
        type: GET_SEARCH_DIAMOND_FORM_BEGIN
    });
    GenerateSeachForm(type).then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: GET_SEARCH_DIAMOND_FORM_SUCESS,
                payload: resp.data.data
            })
        } else {
            dispatch({
                type: GET_SEARCH_DIAMOND_FORM_FAILED,
                payload: []
            })
        }
    })
};

export const getSavedSearch = () => dispatch => {
    dispatch({
        type: GET_SAVED_SEARCH_BEGIN
    });
    GetSavedSearch().then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: GET_SAVED_SEARCH_SUCESS,
                payload: resp.data.data
            })
        } else {
            dispatch({
                type: GET_SAVED_SEARCH_SUCESS,
                payload: []
            })
        }
    })
};

export const getRecentSearch = () => dispatch => {
    dispatch({
        type: GET_RECENT_SEARCH_BEGIN
    });
    GetRecentSearch().then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: GET_RECENT_SEARCH_SUCESS,
                payload: resp.data.data
            })
        } else {
            dispatch({
                type: GET_RECENT_SEARCH_SUCESS,
                payload: []
            })
        }
    })
};

export const postSavedSearch = (payload) => dispatch => {
    PostSavedSearch(payload).then(({ data }) => {
        if (data?.success) {
            if (data?.status === 'block') {
                dispatch(getRecentSearch())
            } else {
                dispatch(getSavedSearch())
                NotificationManager.success('Successfully search saved', 'Success');
            }
        } else {
            NotificationManager.error('Failed to save search, please try again later.', 'Error');
        }
    })
};