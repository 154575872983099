import React from "react";

function ProductItemAddRow({
  columns,
  searchedProducts,
  product,
  codeList,
  addProductItem,
}) {
  const style = {
    width: "auto",
    whiteSpace: "pre-wrap",
  };
  return (
    <tr>
      {columns.map((col, i) => {
        let colStyle = {
          ...style,
        };
        if (col.width) {
          colStyle = {
            ...colStyle,
            width: col.width,
          };
        }

        switch (col.dataIndex) {
          case "code":
            return (
              <td key={i} style={colStyle} className={col.className}>
                <div className="form-group">
                  <select
                    id="picker1"
                    className="form-control"
                    name="code"
                    value={searchedProducts}
                    onChange={(e) => {
                      const selectCode = e.target.value;
                      if (!selectCode) {
                        return false;
                      }
                      addProductItem(
                        product?.group_by,
                        product.brand_code,
                        selectCode
                      );
                    }}
                  >
                    <option value="">Select Product Item Code</option>
                    {!!codeList?.length &&
                      codeList?.map((item) => {
                        return <option value={item?.code}>{item?.code}</option>;
                      })}
                  </select>
                </div>
              </td>
            );
          default:
            return <td key={i} style={colStyle} className={col.className}></td>;
        }
      })}
    </tr>
  );
}

export default React.memo(ProductItemAddRow);
