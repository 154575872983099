import {
  getCustomer,
  getCustomerList,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerListWithQuotation,
} from "app/services/CustomerService";
import { NotificationManager } from "react-notifications";

export const GET_CUSTOMER_BEGIN = "GET_CUSTOMER_BEGIN";
export const GET_CUSTOMER_SUCESS = "GET_CUSTOMER_SUCESS";
export const GET_CUSTOMER_FAILED = "GET_CUSTOMER_FAILED";

export const CREATE_CUSTOMER_BEGIN = "CREATE_CUSTOMER_BEGIN";
export const CREATE_CUSTOMER_SUCESS = "CREATE_CUSTOMER_SUCESS";
export const CREATE_CUSTOMER_FAILED = "CREATE_CUSTOMER_FAILED";

export const fetchCustomers = (data) => (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_BEGIN,
  });
  getCustomer(data).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_CUSTOMER_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: GET_CUSTOMER_SUCESS,
        payload: [],
      });
    }
  });
};
export const fetchCustomersList = () => (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_BEGIN,
  });
  getCustomerList().then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_CUSTOMER_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: GET_CUSTOMER_SUCESS,
        payload: [],
      });
    }
  });
};
export const fetchCustomerListWithQuotation = (params) => (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_BEGIN,
  });
  return getCustomerListWithQuotation(params).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_CUSTOMER_SUCESS,
        payload: resp?.data?.data,
      });
      return resp?.data?.data;
    } else {
      dispatch({
        type: GET_CUSTOMER_SUCESS,
        payload: [],
      });
      return [];
    }
  });
};
export const CreateCustomer = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_CUSTOMER_BEGIN,
  });
  return createCustomer(payload).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: CREATE_CUSTOMER_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: CREATE_CUSTOMER_SUCESS,
        payload: [],
      });
    }
    return resp?.data?.data;
  });
};
export const UpdateCustomer = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_CUSTOMER_BEGIN,
  });
  return updateCustomer(payload).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: CREATE_CUSTOMER_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: CREATE_CUSTOMER_SUCESS,
        payload: [],
      });
    }
    return resp?.data?.data;
  });
};

export const DeleteCustomer = (payload) => (dispatch) => {
  return deleteCustomer(payload)
    .then((resp) => {
      if (resp?.data) {
        NotificationManager.success("Customer deleted successfully");
        return resp?.data;
      } else {
        NotificationManager.error(
          "Fail to delete Customer, Please try again. err - " +
            resp?.data?.error
        );
      }
    })
    .catch((e) => {
      NotificationManager.error(
        "Fail to delete Customer, Please try again. err - " + e
      );
    });
};
