const { apiAdapter } = require("./ApiAdapter")

const GenerateSeachForm = type => {
    return apiAdapter({
        url: 'search/field',
        method: 'GET',
        params: {
            type
        }
    })
}

const GetSavedSearch = () => {
    return apiAdapter({
        url: 'user/saved-search',
        method: 'GET'
    })
}

const GetRecentSearch = () => {
    return apiAdapter({
        url: 'user/recent-search',
        method: 'GET'
    })
}

const PostSavedSearch = (data) => {
    return apiAdapter({
        url: 'user/saved-search',
        method: 'POST',
        data
    })
}
const DeleteSavedSearch = (data) => {
    return apiAdapter({
        url: 'user/saved-search',
        method: 'DELETE',
        data
    })
}


module.exports = {
    GenerateSeachForm,
    GetSavedSearch,
    GetRecentSearch,
    PostSavedSearch,
    DeleteSavedSearch
}