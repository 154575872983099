import React, { Component } from "react";
import './style.scss';
import { Formik } from "formik";
import * as yup from "yup";
import { loginWithEmailAndPassword } from "app/redux/actions/LoginActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spin from "@gull/components/Spin";

const SigninSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email")
        .required("email is required"),
    password: yup
        .string()
        .min(6, "Password must be 6 character long")
        .required("password is required")
});

class Login extends Component {
    state = {
        email: "",
        password: ""
    };

    handleChange = event => {
        event.persist();
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSubmit = (value, { isSubmitting }) => {
        this.props.loginWithEmailAndPassword(value);
    };

    render() {
        return (
            <div className="login">
                <div className="row no-gutters flex-row auth-wrap">
                    <div className="col-lg-7 p-5 d-flex justify-content-center align-items-center">
                        {/* <img src='/assets/images/login/login-4.svg' alt="login" /> */}
                        <img src='/assets/images/logo-1.jpg' alt="logo" />
                    </div>
                    <div className="col-lg-5 bg-dark d-flex justify-content-center align-items-center">
                        <div className="auth-form-wrap mx-auto mt-5">
                            <h3 className="m--font-normal mb-5 text-white"><span className="text-primary">Welcome Back,</span> <br /> Please login with your
                account</h3>
                            <div className="auth-form mb-5">
                                <Formik
                                    initialValues={this.state}
                                    validationSchema={SigninSchema}
                                    onSubmit={this.handleSubmit}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting
                                    }) => (
                                        <Spin loading={this.props.loading} >

                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <div className="form-control--icon form-control--icon-left">
                                                        <span className="form-control--icon-wrapper">
                                                            <span>
                                                                <i className="ion-md-contact" />
                                                            </span>
                                                        </span>
                                                        <input
                                                            type="text"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                            className="form-control"
                                                            placeholder="Email"
                                                            name="email"
                                                        />
                                                        {errors.email && (
                                                            <div className="text-danger mt-1 ml-2">
                                                                {errors.email}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-control--icon form-control--icon-left">
                                                        <span className="form-control--icon-wrapper">
                                                            <span>
                                                                <i className="ion-md-lock" />
                                                            </span>
                                                        </span>
                                                        <input
                                                            type="password"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.password}
                                                            className="form-control"
                                                            placeholder="Password"
                                                            name="password"
                                                        />
                                                        {errors.password && (
                                                            <div className="text-danger mt-1 ml-2">
                                                                {errors.password}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex align-items-center">
                                                    {/* <div className="custom-control custom-checkboxcustom-control custom-checkbox custom-checkbox--primary"><input type="checkbox" className="custom-control-input" id="rm" /> <label className="custom-control-label" htmlFor="rm">Remember me</label></div> */}
                                                    {/* <Link to="/session/forgot-password" className="ml-auto text-primary">Forgot password?</Link> */}
                                                </div>
                                                <button type="submit" className="btn btn-primary px-5">Login</button>
                                            </form>
                                        </Spin>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    user: state.user,
    loading: state.login.loading
});

export default connect(mapStateToProps, {
    loginWithEmailAndPassword
})(Login);
