/* eslint-disable */
import React, { Component } from "react";
import './AdminUsersStyle.scss';
import { Breadcrumb } from "@gull";
import Table from "app/GullLayout/SharedComponents/table/Table";
import { Button, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Spin from "@gull/components/Spin";
import { connect } from "react-redux";
import { fetchUsers, updateNewUser } from '../../../redux/actions/UserActions';
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import { createRecommendedProducts, getSearchedProducts } from 'app/services/ProductService'
import { NotificationManager } from "react-notifications";

class AdminUsers extends Component {
    state = {
        windowWidth: window.innerWidth,
        columns: [
            {
                title: 'Details',
                dataIndex: 'Details',
                render: (data, row) => (<>
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Edit
                        </Tooltip>
                        }
                    >
                        <Link className="link" to={`/user/form/${window.btoa(row.id)}`}><i className="far fa-edit"></i></Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                {Number(row.status) ? 'Block' : 'Un-Block'}
                            </Tooltip>
                        }
                    >
                        <i onClick={e => this.blockUser(row)} className={`fa ${!Number(row.status) ? 'fa-check text-success' : 'fa-ban text-danger'}`}></i>

                    </OverlayTrigger>
                </>)
            },
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                render: (data, row) => (<Link to={`/user/${window.btoa(row.id)}`}>{data}</Link>)
            },
            {
                title: "Email",
                dataIndex: 'email',
            },
            {
                title: "Mobile No",
                dataIndex: 'mobile_no',
            },
            {
                title: "User Type",
                dataIndex: 'user_type',
            },
            {
                title: "Status",
                dataIndex: 'status',
                className: "text-center",
                render: data => Number(data)
                    ? <Badge key={1} className={`badge-success text-white p-2`}>
                        {'ACTIVE'}
                    </Badge> : <Badge key={2} className={`badge-danger text-white p-2`}>
                        {'INACTIVE'}
                    </Badge>
            },
            {
                title: "Created At",
                dataIndex: 'created_at',
            },
        ],
        openProductRecommendationModal: false,
        selectedRow: {},
        selectedProducts: []
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserData()
    }

    componentDidUpdate(prevProps) {
    }

    getUserData = (filters = {}) => {
        this.props.fetchUsers({ page: 1, ...filters })
    }

    onPageChange = page => {
        this.getUserData({ page: (page + 1) })
    }

    searchUser = e => {
        e.preventDefault();
        if (isNaN(this.state.searchVal)) {
            this.getUserData({ name: this.state.searchVal })
        } else {
            this.getUserData({ mobile_no: this.state.searchVal })
        }
    }
    resetSearchUser = e => {
        e.preventDefault();
        this.setState({
            searchVal: ''
        }, () => this.getUserData({}))
    }

    blockUser = row => {
        const payload = {
            id: row.id,
            status: !Number(row.status) ? 'active' : 'block'
        }
        this.props.updateNewUser(payload).then(resp => {
            this.getUserData()
        })
    }

    openProductRecommendationModal = row => {
        this.setState({
            selectedRow: row,
            openProductRecommendationModal: !this.state.openProductRecommendationModal,
            selectedProducts: [],
            searchDVal: ''
        })
    }

    searchProduct = e => {
        e.preventDefault();
        getSearchedProducts(1, { stone_id: this.state.searchDVal }).then(resp => {
            if (resp.data?.data?.data?.length > 0) {
                const diamonds = resp.data?.data?.data
                const selectedProducts = this.state.selectedProducts

                if (!selectedProducts.find(i => i.id === diamonds[0]?.id)) {
                    selectedProducts.push(diamonds[0])
                }

                this.setState({
                    selectedProducts,
                    searchDVal: ''
                })
            }
        })
    }

    createRecommendation = () => {
        if (this.state.selectedProducts.length === 0) {
            NotificationManager.error('No diamond selected to recommend the user')
            return;
        }

        const payload = {
            userid: this.state.selectedRow.id,
            diamondIds: this.state.selectedProducts.map(i => i.id)
        }
        createRecommendedProducts(payload).then(resp => {
            if (resp?.data?.data) {
                NotificationManager.success('Successfully Recommanded to the user')
            } else {
                NotificationManager.error('Some error occur. Please contact admin')
            }
            this.openProductRecommendationModal({})
        })
    }

    render() {
        const { columns, openProductRecommendationModal, selectedRow, selectedProducts } = this.state;
        const { loading, userList, currentPage, totalRow, perPage } = this.props;
        const buttonList = [
        //     <Button
        //         key={1}
        //         variant={`outline-primary`}
        //         className="btn-rounded m-1 text-capitalize right-float-patch"
        //         style={{ float: 'right' }}
        //         onClick={e => this.props.history.push('/user/form')}
        //     >
        //         Create User
        //   </Button>
        ]
        const buttonListmerge = [
        //     <Button
        //         key={1}
        //         variant={`outline-primary`}
        //         className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        //         style={{ float: 'right' }}
        //         onClick={e => this.props.history.push('/user/form')}
        //     >
        //         Create User
        //   </Button>
        ]
        let switchlist = []
        if (this.state.windowWidth > 600) {
            switchlist = [...switchlist, ...buttonListmerge]
        }
        return (
            <div className="admin-users">
                <Breadcrumb
                    routeSegments={[
                        { name: 'User List', path: "/users" }
                    ]}
                    extra={switchlist}
                    patchingclass={'pad-2rem-petch'}
                    switchlist_bc={buttonList}
                ></Breadcrumb>
                <div className="row pad-2rem-petch">
                    <div className="col-md-4 form-group mb-3">
                        <form onSubmit={e => this.searchUser(e)}>
                            <div class="input-group border rounded-pill">
                                <input type="search" value={this.state.searchVal} onChange={e => this.setState({ searchVal: e.target.value })} placeholder="Search by Name or Phone No" class="form-control form-control-rounded bg-none border-0" />
                                <div class="input-group-append border-0">
                                    {
                                        !!this.state.searchVal && <button type="button" onClick={this.resetSearchUser} class="btn btn-outline-primary btn-rounded"> <i className="fa fa-times" /> </button>
                                    }
                                    <button type="submit" class="btn btn-primary btn-rounded">Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <Spin loading={loading}>
                            <Table
                                dataSource={userList}
                                bordered={true}
                                columns={columns}
                                // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                                paginate={false}
                            />
                        </Spin>
                    </div>
                </div>
                <div className="breaker"></div>
                <div className="sticky-footer">
                    <div className="container">
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            <ReactPaginate
                                previousLabel={<i className="i-Previous"></i>}
                                nextLabel={<i className="i-Next1"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={(page) => this.onPageChange(page.selected)}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                            />
                        </div>
                    </div>
                </div>
                <AppModal
                    show={openProductRecommendationModal}
                    title={'Product Recommendation'}
                    size="md"
                    onHide={() => this.openProductRecommendationModal({})}
                >
                    <p>Please select diamonds for recommendation for <b>{selectedRow?.name}</b></p>
                    <form onSubmit={e => this.searchProduct(e)}>
                        <div class="input-group border rounded-pill">
                            <input type="search" value={this.state.searchDVal} onChange={e => this.setState({ searchDVal: e.target.value })} placeholder="Enter Product Stone No" class="form-control form-control-rounded bg-none border-0" />
                            <div class="input-group-append border-0">
                                {
                                    !!this.state.searchDVal && <button type="button" onClick={this.resetSearchUser} class="btn btn-outline-primary btn-rounded"> <i className="fa fa-times" /> </button>
                                }
                                <button type="submit" class="btn btn-primary btn-rounded">Search</button>
                            </div>
                        </div>
                    </form>
                    <p className="mt-3">Selected Product List:</p>
                    <Table
                        dataSource={selectedProducts}
                        bordered={true}
                        columns={[
                            {
                                title: 'ID',
                                dataIndex: 'id',
                            },
                            {
                                title: 'Stone No',
                                dataIndex: 'stone_id'
                            },
                            {
                                title: 'Price/Cts.',
                                dataIndex: 'j_price'
                            },
                            {
                                title: 'Total Amt',
                                dataIndex: 'j_total_amount'
                            },

                        ]}
                        width={200}
                        // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                        paginate={false}
                    />
                    <Button
                        key={1}
                        variant={`primary`}
                        className="btn-rounded m-1 text-capitalize right-float-patch"
                        style={{ float: 'right' }}
                        onClick={e => this.createRecommendation()}
                    >
                        {`Send Recommandations`}
                    </Button>
                </AppModal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    loading: state.user.loading,
    userList: state.user.userList?.data,
    currentPage: state.user.userList?.current_page,
    totalRow: state.user.userList?.total,
    perPage: state.user.userList?.per_page,
});
const mapDispatchToProps = {
    fetchUsers,
    updateNewUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminUsers));