import {
  GetQuotations,
  CreateQuotation,
  UpdateQuotation,
  GenereateId,
  DeleteQuotation,
  SoftDeleteQuotation,
  ExportQuotation,
  PdfExportQuotation,
  GetQuotationTerms,
  CreateQuotationTerms,
} from "app/services/QuotationService";
import { NotificationManager } from "react-notifications";

export const GET_QUOTATION_BEGIN = "GET_QUOTATION_BEGIN";
export const GET_QUOTATION_SUCESS = "GET_QUOTATION_SUCESS";
export const GET_QUOTATION_FAILED = "GET_QUOTATION_FAILED";

export const CREATE_QUOTATION_BEGIN = "CREATE_QUOTATION_BEGIN";
export const CREATE_QUOTATION_SUCESS = "CREATE_QUOTATION_SUCESS";
export const CREATE_QUOTATION_FAILED = "CREATE_QUOTATION_FAILED";

export const UPDATE_QUOTATION_BEGIN = "UPDATE_QUOTATION_BEGIN";
export const UDATE_QUOTATION_SUCESS = "UDATE_QUOTATION_SUCESS";
export const UDATE_QUOTATION_FAILED = "UDATE_QUOTATION_FAILED";

export const fetchQuotations = (params) => (dispatch) => {
  dispatch({
    type: GET_QUOTATION_BEGIN,
  });
  return GetQuotations(params).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_QUOTATION_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: GET_QUOTATION_SUCESS,
        payload: [],
      });
    }
    return resp
  });
};
export const GenereateCode = (params) => (dispatch) => {
  return GenereateId(params);
};
export const createQuotation = (payload) => (dispatch) => {
  return CreateQuotation(payload)
    .then((resp) => {
      if (resp?.data) {
        NotificationManager.success("Quotation created successfully");
        return resp?.data;
      } else {
        NotificationManager.error(
          "Fail to create Quotation, Please try again."
        );
      }
    })
    .catch((e) => {
      NotificationManager.error(
        "Fail to create Quotation, Please try again. err - " + e
      );
    });
};
export const updateQuoation = (payload) => (dispatch) => {
  return UpdateQuotation(payload)
    .then((resp) => {
      if (resp?.data) {
        NotificationManager.success("Quotation updated successfully");
        return resp?.data;
      } else {
        NotificationManager.error(
          "Fail to update Quotation, Please try again."
        );
      }
    })
    .catch((e) => {
      NotificationManager.error(
        "Fail to update Quotation, Please try again. err - " + e
      );
    });
};
export const softDeleteQuoation = (payload) => (dispatch) => {
  return SoftDeleteQuotation(payload)
    .then((resp) => {
      if (resp?.data) {
        NotificationManager.success(
          `Quotation ${
            !payload?.status ? "inactivated" : "activated"
          } successfully`
        );
        return resp?.data;
      } else {
        NotificationManager.error(
          "Fail to delete Quotation, Please try again. err - " +
            resp?.data?.error
        );
      }
    })
    .catch((e) => {
      NotificationManager.error(
        "Fail to delete Quotation, Please try again. err - " + e
      );
    });
};
export const deleteQuoation = (payload) => (dispatch) => {
  return DeleteQuotation(payload)
    .then((resp) => {
      if (resp?.data) {
        NotificationManager.success("Quotation deleted successfully");
        return resp?.data;
      } else {
        NotificationManager.error(
          "Fail to delete Quotation, Please try again. err - " +
            resp?.data?.error
        );
      }
    })
    .catch((e) => {
      NotificationManager.error(
        "Fail to delete Quotation, Please try again. err - " + e
      );
    });
};
export const exportQuotation = (payload) => (dispatch) => {
  return ExportQuotation(payload)
    .then((resp) => {
      if (resp?.data) {
        // NotificationManager.success("Quotation export successfully");
        return resp?.data;
      } else {
        NotificationManager.error(
          "Fail to export Quotation, Please try again. err - " +
            resp?.data?.error
        );
      }
    })
    .catch((e) => {
      NotificationManager.error(
        "Fail to export Quotation, Please try again. err - " + e
      );
    });
};
export const pdfExportQuotation = (payload) => (dispatch) => {
  return PdfExportQuotation(payload)
    .then((resp) => {
      if (resp?.data) {
        // NotificationManager.success("Quotation export successfully");
        return resp?.data;
      } else {
        NotificationManager.error(
          "Fail to export Quotation, Please try again. err - " +
            resp?.data?.error
        );
      }
    })
    .catch((e) => {
      NotificationManager.error(
        "Fail to export Quotation, Please try again. err - " + e
      );
    });
};
export const fetchQuotationsTerms = (params) => () => {
  return GetQuotationTerms(params).then((resp) => {
    return resp?.data?.data;
  });
};
export const createQuotationsTerms = (data) => () => {
  return CreateQuotationTerms(data).then((resp) => {
    return resp?.data?.data;
  });
};
