import React, { useEffect } from "react";
import { useState } from "react";

export default function Image(props) {
  const [isDefaultImg, setIsDefaultImg] = useState(false);

  useEffect(() => {
    setIsDefaultImg(false);
  }, [props.src]);

  return !isDefaultImg ? (
    <img
      alt=""
      onError={(e) => {
        setIsDefaultImg(true);
      }}
      {...props}
    />
  ) : (
    <img
      width="100px"
      {...props}
      src={`${process.env.PUBLIC_URL}/assets/images/no-image.jpg`}
      alt="N S Solution logo"
    />
  );
}
