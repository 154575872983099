import React, { Component } from 'react'
import { Breadcrumb } from '@gull';
import Table from 'app/GullLayout/SharedComponents/table/Table';
import { connect } from 'react-redux';
import { fetchUserEnquiry } from '../../../redux/actions/UserActions';
import ReactPaginate from 'react-paginate';
import Spin from '@gull/components/Spin';

class AdminEnquiries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            columns: [
                {
                    title: 'Enquiry ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                },
                {
                    title: 'Phone',
                    dataIndex: 'phone',
                },
                {
                    title: 'Message',
                    dataIndex: 'message',
                },
                {
                    title: 'Created On',
                    dataIndex: 'created_at',
                },
            ],
        }
        const payload = { page: 1 }
        if (this.props.location?.state?.enquiryId)
            payload['id'] = this.props.location.state.enquiryId
        this.props.fetchUserEnquiry(payload, true)
    }
    onPageChange = page => {
        this.props.fetchUserEnquiry({ page: (page + 1) }, true)
    }
    render() {
        const { columns } = this.state;
        const { enquiryData, enquiryLoading, currentPage, totalRow, perPage } = this.props;
        return <div className="admin-enquiry">
            <Breadcrumb
                routeSegments={[
                    { name: 'Enquiry List', path: "/enquiries" }
                ]}
                extra={[]}
                patchingclass={'pad-2rem-petch'}
                switchlist_bc={[]}
            ></Breadcrumb>
            <Spin loading={enquiryLoading}>
                {/* <div className="row">
                    <div className="col-md-4 form-group mb-3">
                        <form onSubmit={e => this.searchOrder(e)}>
                            <div className="input-group border rounded-pill">
                                <input type="search" value={this.state.searchVal} onChange={e => this.setState({ searchVal: e.target.value })} placeholder="Search by Email" className="form-control form-control-rounded bg-none border-0" />
                                <div className="input-group-append border-0">
                                    {
                                        !!this.state.searchVal && <button type="button" onClick={this.resetSearchUser} className="btn btn-outline-primary btn-rounded"> <i className="fa fa-times" /> </button>
                                    }
                                    <button type="submit" className="btn btn-primary btn-rounded">Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> */}
                <Table
                    dataSource={enquiryData}
                    bordered={true}
                    columns={columns}
                    // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                    paginate={false}
                />
                <div className="sticky-footer">
                    <div className="container">
                        <div className="d-flex justify-content-center">
                            <ReactPaginate
                                previousLabel={<i className="i-Previous"></i>}
                                nextLabel={<i className="i-Next1"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={(page) => this.onPageChange(page.selected)}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                            />
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    }
}
const mapStateToProps = (state) => ({
    enquiryData: state.user?.userEnquiryData?.data ? state.user?.userEnquiryData?.data.length ? state.user?.userEnquiryData?.data : Object.keys(state.user?.userEnquiryData?.data).map(item => (state.user?.userEnquiryData?.data[item])) : [],
    enquiryLoading: state.user?.userEnquiryLoading,
    currentPage: state.user?.userEnquiryData?.current_page,
    totalRow: state.user?.userEnquiryData?.total,
    perPage: state.user?.userEnquiryData?.per_page,
})

const mapDispatchToProps = {
    fetchUserEnquiry
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEnquiries);