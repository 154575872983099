import {
  getCountries,
  getStates,
  getCities,
  adminDashboardStats,
} from "app/services/UserService";

export const GET_COUNTRIES_BEGIN = "GET_COUNTRIES_BEGIN";
export const GET_COUNTRIES_SUCESS = "GET_COUNTRIES_SUCESS";
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_FAILED";

export const GET_STATES_BEGIN = "GET_STATES_BEGIN";
export const GET_STATES_SUCESS = "GET_STATES_SUCESS";
export const GET_STATES_FAILED = "GET_STATES_FAILED";

export const GET_CITIES_BEGIN = "GET_CITIES_BEGIN";
export const GET_CITIES_SUCESS = "GET_CITIES_SUCESS";
export const GET_CITIES_FAILED = "GET_CITIES_FAILED";

export const GET_ADMIN_DASHBOARD_STATS_BEGIN =
  "GET_ADMIN_DASHBOARD_STATS_BEGIN";
export const GET_ADMIN_DASHBOARD_STATS_SUCESS =
  "GET_ADMIN_DASHBOARD_STATS_SUCESS";
export const GET_ADMIN_DASHBOARD_STATS_FAILED =
  "GET_ADMIN_DASHBOARD_STATS_FAILED";

export const fetchCountries = (params) => (dispatch) => {
  dispatch({
    type: GET_COUNTRIES_BEGIN,
  });
  getCountries(params).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_COUNTRIES_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: GET_COUNTRIES_SUCESS,
        payload: [],
      });
    }
  });
};
export const fetchStates = (params) => (dispatch) => {
  dispatch({
    type: GET_STATES_BEGIN,
  });
  getStates(params).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_STATES_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: GET_STATES_SUCESS,
        payload: [],
      });
    }
  });
};
export const fetchCities = (params) => (dispatch) => {
  dispatch({
    type: GET_CITIES_BEGIN,
  });
  getCities(params).then((resp) => {
    if (resp?.data?.data) {
      dispatch({
        type: GET_CITIES_SUCESS,
        payload: resp?.data?.data,
      });
    } else {
      dispatch({
        type: GET_CITIES_SUCESS,
        payload: [],
      });
    }
  });
};

export const AdminDashboardStats = () => (dispatch) => {
  dispatch({
    type: GET_ADMIN_DASHBOARD_STATS_BEGIN,
  });
  adminDashboardStats()
    .then((resp) => {
      if (resp?.data?.data) {
        dispatch({
          type: GET_ADMIN_DASHBOARD_STATS_SUCESS,
          payload: resp?.data?.data,
        });
      } else {
        dispatch({
          type: GET_ADMIN_DASHBOARD_STATS_FAILED,
          payload: [],
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ADMIN_DASHBOARD_STATS_FAILED,
        payload: [],
      });
    });
};
