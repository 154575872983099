import React, { Component } from "react";
import { Breadcrumb } from "@gull";
import Spin from "@gull/components/Spin";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchQuotations } from "../../../redux/actions/QuotationActions";

class AdminReport extends Component {
  state = {
    quotationCode: this.props?.match?.params?.quotationCode,
    searchVal: "",
    loading: false,
  };

  getQuotationData = (filters = {}) => {
    this.props.fetchQuotations({ page: 1, ...filters });
  };
  searchQuotation = (e) => {
    e.preventDefault();
    this.getQuotationData({ code: this.state.searchVal });
    // if (isNaN(this.state.searchVal)) {
    //   // this.getQuotationData({ party_name: this.state.searchVal });
    // } else {
    //   this.getQuotationData({ code: this.state.searchVal });
    // }
  };
  resetSearchQuotation = (e) => {
    e.preventDefault();
    this.setState(
      {
        searchVal: "",
      },
      () => this.getQuotationData({})
    );
  };

  componentDidMount() {}
  render() {
    const { loading } = this.state;
    const buttonList = [];
    let switchlist = [];
    return (
      <div className="admin-report">
        <Breadcrumb
          routeSegments={[{ name: "Quotation Reports", path: "/report" }]}
          extra={switchlist}
          patchingclass={"pad-2rem-petch"}
          switchlist_bc={buttonList}
        ></Breadcrumb>
        <div className="row pad-2rem-petch">
          <div className="col-md-4 form-group mb-3">
            <form onSubmit={(e) => this.searchQuotation(e)}>
              <div class="input-group border rounded-pill">
                <input
                  type="search"
                  value={this.state.searchVal}
                  onChange={(e) => this.setState({ searchVal: e.target.value })}
                  placeholder="Generate Report by Quotation Code"
                  class="form-control form-control-rounded bg-none border-0"
                />
                <div class="input-group-append border-0">
                  {!!this.state.searchVal && (
                    <button
                      type="button"
                      onClick={this.resetSearchQuotation}
                      class="btn btn-outline-primary btn-rounded"
                    >
                      {" "}
                      <i className="fa fa-times" />{" "}
                    </button>
                  )}
                  <button type="submit" class="btn btn-primary btn-rounded">
                    Check
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-12 col-md-12">
            <Spin loading={loading}></Spin>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.quotation.loading,
  quotationList: state.quotation?.data,
});
const mapDispatchToProps = {
  fetchQuotations,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminReport));
