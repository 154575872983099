import React from "react";

function QuotationManifoldRow({
  disabled,
  readOnly,
  columns,
  product,
  index,
  productLength,
  handleManifoldCalculations,
}) {
  const style = {
    width: "auto",
    whiteSpace: "pre-wrap",
  };
  console.log("product", product);
  console.log("columns", columns);
  return (
    <tr className={disabled ? "disabled" : ""}>
      {columns.map((col, i) => {
        let colStyle = {
          ...style,
        };
        if (col.width) {
          colStyle = {
            ...colStyle,
            width: col.width,
          };
        }

        switch (col.dataIndex) {
          case "id":
            return (
              <td style={colStyle} className={col.className}>
                {index + 1}
              </td>
            );
          case "description":
            return (
              <td key={i} style={colStyle} className={col.className}>
                {/* <b>Manifold Description</b> */}
                {readOnly ? (
                  <div className="form-group">{product?.manifold_desc}</div>
                ) : (
                  <div className="form-group">
                    <input
                      className="form-control position-relative"
                      type="text"
                      name={"description"}
                      onChange={(e) => {
                        handleManifoldCalculations(
                          index - productLength,
                          "manifold_desc",
                          e.target?.value ?? ""
                        );
                      }}
                      value={product?.manifold_desc}
                      placeholder={col.dataIndex}
                    />
                  </div>
                )}
              </td>
            );
          case "quantity":
          case "sales_discount":
          case "purchase_discount":
            return (
              <td key={i} style={colStyle} className={col.className}>
                {readOnly ? (
                  <div className="form-group">
                    <div
                      className="form-control position-relative"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      {product[col.dataIndex]}
                    </div>
                  </div>
                ) : (
                  <div className="form-group">
                    <input
                      className="form-control position-relative"
                      type="text"
                      name={col.dataIndex}
                      onChange={(e) => {
                        handleManifoldCalculations(
                          index - productLength,
                          e.target?.name,
                          e.target?.value ?? 0
                        );
                      }}
                      value={product[col.dataIndex]}
                      placeholder={col.dataIndex}
                    />
                  </div>
                )}
              </td>
            );
          case "price":
          case "sales_net_amount":
          case "sales_total_amount":
          case "purchase_net_amount":
          case "purchase_total_amount":
            return (
              <td key={i} style={colStyle} className={col.className}>
                <div className="form-group">
                  <div
                    className="form-control position-relative"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  >
                    {Number(product[col.dataIndex]).toFixed(2)}
                  </div>
                </div>
              </td>
            );
          default:
            return (
              <td key={i} style={colStyle} className={col.className}>
                -
              </td>
            );
        }
      })}
    </tr>
  );
}

export default React.memo(QuotationManifoldRow);
