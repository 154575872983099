import { GET_COUNTRIES_BEGIN, GET_COUNTRIES_SUCESS, GET_COUNTRIES_FAILED, GET_STATES_BEGIN, GET_STATES_SUCESS, GET_STATES_FAILED, GET_CITIES_BEGIN, GET_CITIES_SUCESS, GET_CITIES_FAILED, GET_ADMIN_DASHBOARD_STATS_BEGIN, GET_ADMIN_DASHBOARD_STATS_SUCESS, GET_ADMIN_DASHBOARD_STATS_FAILED } from "../actions/MiscActions";

const initialState = {};

const MiscReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_COUNTRIES_BEGIN: {
            return {
                ...state,
                countryloading: true
            };
        }
        case GET_COUNTRIES_SUCESS: {
            return {
                ...state,
                countryloading: false,
                countryList: [...action.payload]
            };
        }
        case GET_COUNTRIES_FAILED: {
            return {
                ...state,
                countryloading: false,
                countryList: {},
                countryError: action.payload
            };
        }
        case GET_STATES_BEGIN: {
            return {
                ...state,
                stateloading: true
            };
        }
        case GET_STATES_SUCESS: {
            return {
                ...state,
                stateloading: false,
                stateList: [...action.payload]
            };
        }
        case GET_STATES_FAILED: {
            return {
                ...state,
                stateloading: false,
                stateList: {},
                stateError: action.payload
            };
        }
        case GET_CITIES_BEGIN: {
            return {
                ...state,
                cityloading: true
            };
        }
        case GET_CITIES_SUCESS: {
            return {
                ...state,
                cityloading: false,
                cityList: [...action.payload]
            };
        }
        case GET_CITIES_FAILED: {
            return {
                ...state,
                cityloading: false,
                cityList: {},
                cityError: action.payload
            };
        }
        case GET_ADMIN_DASHBOARD_STATS_BEGIN: {
            return {
                ...state,
                adminStatsloading: true
            };
        }
        case GET_ADMIN_DASHBOARD_STATS_SUCESS: {
            return {
                ...state,
                adminStatsloading: false,
                adminStats: { ...action.payload }
            };
        }
        case GET_ADMIN_DASHBOARD_STATS_FAILED: {
            return {
                ...state,
                adminStatsloading: false,
                adminStats: {},
                adminStatsError: action.payload
            };
        }
        case 'PROGRESS_BAR_VALUE': {
            return {
                ...state,
                progressBarValue: action.value
            }
        }
        case 'RESET_PROGRESS_BAR_VALUE': {
            return {
                ...state,
                progressBarValue: action.value
            }
        }
        default: {
            return state;
        }
    }
};

export default MiscReducer;
