/* eslint-disable */
import React, { Component } from "react";
import "./AdminProductStyle.scss";
import { Breadcrumb } from "@gull";
import Table from "app/GullLayout/SharedComponents/table/Table";
import { Button, OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Spin from "@gull/components/Spin";
import { connect } from "react-redux";
import {
  fetchFeaturedProduct,
  fetchRecommendedProduct,
  fetchSearchedProduct,
  fetchNewProduct,
  createProductEnquiry,
  createProductPlaceOrder,
  updateNewProduct,
  exportProducts,
  deleteProduct,
} from "../../../redux/actions/ProductActions";
import { NotificationManager } from "react-notifications";
import BulkProductUpload from "app/GullLayout/SharedComponents/bulk-upload/BulkProductUpload";
import { storageUrl } from "environment/env";
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import { FILE_FORMATS, MEDIA_TAB } from "@utils";
import Image from "app/GullLayout/SharedComponents/Image";

class AdminProduct extends Component {
  state = {
    windowWidth: window.innerWidth,
    filepath: "product",
    columns: [
      {
        title: "Details",
        dataIndex: "details",
        render: (data, row) => (
          <>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>}
            >
              <Link
                className="link"
                to={{
                  pathname: `/product/form/${window.btoa(row.id)}`,
                  state: { row },
                }}
              >
                <i className="far fa-edit"></i>
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}
            >
              <i
                className={`fa fa-trash`}
                onClick={() => {
                  const that = this;
                  this.setState(
                    {
                      deleteLoading: false,
                      isOpenDeleteBox: true,
                      selectedList: [row],
                    },
                    () => {
                      that.openDeleteBox();
                    }
                  );
                }}
                style={{ color: "red", marginLeft: 6 }}
              ></i>
            </OverlayTrigger>
          </>
        ),
      },
      {
        title: "Media",
        dataIndex: "media",
        render: (data, row) => (
          <>
            {row.image_url ? (
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>{MEDIA_TAB.IMAGE}</Tooltip>
                }
              >
                <i
                  onClick={(e) => {
                    this.setState({
                      selectedRow: row,
                      openModal: true,
                      selectedKey: MEDIA_TAB.IMAGE,
                    });
                  }}
                  className="i-Camera mr-1"
                ></i>
              </OverlayTrigger>
            ) : null}
            {row.pdf_url ? (
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip id={`tooltip-top`}>{MEDIA_TAB.PDF}</Tooltip>}
              >
                <i
                  onClick={(e) => {
                    this.setState({
                      selectedRow: row,
                      openModal: true,
                      selectedKey: MEDIA_TAB.PDF,
                    });
                  }}
                  className="i-File mr-1"
                ></i>
              </OverlayTrigger>
            ) : null}
          </>
        ),
      },
      {
        title: "Brand Code",
        dataIndex: "brand_code",
      },
      {
        title: "Brand",
        dataIndex: "brand",
      },
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Code",
        dataIndex: "code",
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "40%",
      },
      {
        title: "Presure Rate",
        dataIndex: "presure_rate",
      },
      {
        title: "Size (MM)",
        dataIndex: "size",
      },
      {
        title: "Price",
        dataIndex: "price",
      },
      {
        title: "Sales Dis (%)",
        dataIndex: "sales_discount",
      },
      {
        title: "Purchase Dis (%)",
        dataIndex: "purchase_discount",
      },
      {
        title: "Updated On",
        dataIndex: "updated_at",
      },
      {
        title: "Created On",
        dataIndex: "created_at",
      },
    ],
    openUploadModal: false,
    openImageUploadModal: false,
    selectedList: [],
    isProductSelected: false,
    searchVal: "",
    bulkProductUploadLoading: false,
    exportloading: false,
    isOpenDeleteBox: false,
    pageNo: 1,
    openModal: false,
    sortWith: "",
    sortMethod: true,
    isListView: true,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getProductData();
  }

  componentDidUpdate(prevProps) {}

  getProductData = () => {
    const { filters } = this.props.location.state
      ? this.props.location.state
      : { filters: {} };
    this.props.fetchSearchedProduct(1, filters);
  };

  updateFeatureStatus = (row) => {
    const payload = {
      id: row.id,
      featured: row.featured,
      status: row.status,
    };
    const that = this;
    this.props.updateNewProduct(payload).then((resp) => {
      if (resp?.length > 0) {
        that.getProductData();
      }
    });
  };

  onPageChange = (page) => {
    this.setState({ pageNo: page + 1 });
    const { filters } = this.props.location.state
      ? this.props.location.state
      : { filters: {} };
    this.props.fetchSearchedProduct(page + 1, {
      ...(filters ? filters : {}),
      sortWith: this.state.sortWith,
      sortMethod: this.state.sortMethod,
      search: this.state.searchVal ?? "",
    });
  };

  showSelectedProducts = (isShowing) => {
    let isProductSelected = !isShowing;
    if (this.state.selectedList.length === 0) {
      NotificationManager.warning(
        "Please select any product to show",
        "Warning",
        5000
      );
      isProductSelected = false;
    }

    this.setState({
      isProductSelected,
    });
  };

  onRowSelected = (isChecked, product) => {
    let selectedList = this.state.selectedList;
    if (isChecked) {
      selectedList.push(product);
    } else {
      selectedList = selectedList.filter((item) => item.id !== product.id);
    }
    this.setState({
      selectedList,
    });
  };

  searchProduct = (e) => {
    e.preventDefault();
    this.props.fetchSearchedProduct(1, { search: this.state.searchVal });
  };
  resetSearchProduct = (e) => {
    e.preventDefault();
    this.setState(
      {
        searchVal: "",
      },
      () => this.props.fetchSearchedProduct(1, {})
    );
  };

  exportProduct = (e) => {
    e.preventDefault();
    const { filters } = this.props.location.state
      ? this.props.location.state
      : { filters: {} };
    this.setState(
      {
        exportloading: true,
      },
      () => {
        this.props
          .exportProducts(filters)
          .then((resp) => {
            if (resp?.data?.success) {
              window.open(`${storageUrl}${resp?.data?.url}`);
            } else {
              console.log("");
            }

            this.setState(
              {
                exportloading: false,
              },
              () => this.getProductData()
            );
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              exportloading: false,
            });
          });
      }
    );
  };

  openDeleteBox = () => {
    if (this.state.selectedList.length <= 0) {
      NotificationManager.warning(
        "Please select 1 or more product",
        "Warning",
        5000
      );
      return;
    } else {
      this.setState({
        isOpenDeleteBox: true,
      });
    }
  };

  confirmDeleteAction = () => {
    this.setState(
      {
        deleteLoading: true,
      },
      () => {
        const payload = {
          ids: btoa(
            this.state.selectedList
              .map((item) => item.product_item_id)
              .join(",")
          ),
        };
        this.props.deleteProduct(payload).then((resp) => {
          this.setState({
            deleteLoading: false,
            isOpenDeleteBox: false,
          });
          const { filters } = this.props.location.state
            ? this.props.location.state
            : { filters: {} };
          this.props.fetchSearchedProduct(
            this.state.pageNo,
            filters ? filters : {}
          );
        });
      }
    );
  };

  onSort = (col) => {
    if (["media", "details"].includes(col)) return false;
    const { filters } = this.props.location.state
      ? this.props.location.state
      : { filters: {} };
    this.setState(
      {
        sortWith: col,
        sortMethod: col === this.state.sortWith ? !this.state.sortMethod : true,
      },
      () =>
        this.props.fetchSearchedProduct(1, {
          ...filters,
          sortWith: this.state.sortWith,
          sortMethod: this.state.sortMethod,
        })
    );
  };

  render() {
    const {
      columns,
      exportloading,
      isOpenDeleteBox,
      deleteLoading,
      openModal,
      selectedRow,
      selectedKey,
    } = this.state;
    const { loading, productList, currentPage, totalRow, perPage } = this.props;
    const buttonList = [
      <Spin loading={exportloading}>
        <Button
          key={3}
          variant={`outline-primary`}
          className="btn-rounded m-1 text-capitalize right-float-patch"
          onClick={this.exportProduct}
        >
          {"Export"}
        </Button>
      </Spin>,
      <Button
        key={4}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: "right" }}
        onClick={(e) => this.openDeleteBox()}
      >
        {"Delete Products"}
      </Button>,
      <Button
        key={1}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push("/product/form")}
      >
        {"Add New Product"}
      </Button>,
      // <BulkProductUpload />,
      // <Button
      //     key={1}
      //     variant={`outline-primary`}
      //     className="btn-rounded m-1 text-capitalize right-float-patch"
      //     style={{ float: 'right' }}
      //     onClick={e => this.props.history.push('/files')}
      // >
      //     {'Upload Product File'}
      // </Button>,
      // <BulkProductImageUpload />,
    ];
    const buttonListmerge = [
      <Spin loading={exportloading}>
        <Button
          key={2}
          variant={`outline-primary`}
          className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
          onClick={this.exportProduct}
        >
          {"Export Products"}
        </Button>
      </Spin>,
      <Button
        key={4}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: "right" }}
        onClick={(e) => this.openDeleteBox()}
      >
        {"Delete Products"}
      </Button>,
      <Button
        key={1}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push("/product/form")}
      >
        {"Add New Product"}
      </Button>,
      // <BulkProductUpload className={"parchdisplay"} />,
      // <Button
      //     key={1}
      //     variant={`outline-primary`}
      //     className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
      //     style={{ float: 'right' }}
      //     onClick={e => this.props.history.push('/files')}
      // >
      //     {'Upload Product Files'}
      // </Button>,
      // <BulkProductImageUpload className={'parchdisplay'} />,
    ];
    let switchlist = [
      // <Switch key={0} value={isListView} onChange={val => this.setState({ isListView: val })} />
    ];
    if (this.state.windowWidth > 600) {
      switchlist = [...switchlist, ...buttonListmerge];
    }
    return (
      <div className="admin-product">
        <Breadcrumb
          routeSegments={[{ name: "Product", path: "/product" }]}
          extra={switchlist}
          patchingclass={"pad-2rem-petch"}
          switchlist_bc={buttonList}
        ></Breadcrumb>
        <Spin loading={loading}>
          {
            <div className="row">
              <div className="col-md-4 form-group mb-3">
                <form onSubmit={(e) => this.searchProduct(e)}>
                  <div class="input-group border rounded-pill">
                    <input
                      type="search"
                      value={this.state.searchVal}
                      onChange={(e) =>
                        this.setState({ searchVal: e.target.value })
                      }
                      placeholder="Search.."
                      class="form-control form-control-rounded bg-none border-0"
                    />
                    <div class="input-group-append border-0">
                      {!!this.state.searchVal && (
                        <button
                          type="button"
                          onClick={this.resetSearchProduct}
                          class="btn btn-outline-primary btn-rounded"
                        >
                          {" "}
                          <i className="fa fa-times" />{" "}
                        </button>
                      )}
                      <button type="submit" class="btn btn-primary btn-rounded">
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-12 col-md-12">
                <Table
                  dataSource={productList}
                  bordered={true}
                  columns={columns}
                  isCheckbox={true}
                  onCheckboxSelect={(isChecked, row) =>
                    this.onRowSelected(isChecked, row)
                  }
                  paginate={false}
                  selectedRows={this.state.selectedList}
                  onSort={this.onSort}
                  sortedOn={this.state.sortWith}
                  sortMethod={this.state.sortMethod}
                  width={"70vh"}
                />
              </div>
            </div>
          }
        </Spin>

        <div className="breaker"></div>
        <div className="sticky-footer">
          <div className="container">
            <div className="d-flex justify-content-center mt-3 mb-3">
              <ReactPaginate
                previousLabel={<i className="i-Previous"></i>}
                nextLabel={<i className="i-Next1"></i>}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(
                  totalRow / perPage >= 1 ? totalRow / perPage : 1
                )}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={(page) => this.onPageChange(page.selected)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={currentPage - 1 ? currentPage - 1 : 0}
              />
            </div>
          </div>
        </div>
        <AppModal
          show={openModal}
          title={selectedRow ? selectedRow.stone_id : ""}
          footer={false}
          size="md"
          onHide={() => this.setState({ openModal: false })}
        >
          <Tab.Container
            id="left-tabs-example"
            activeKey={selectedKey}
            defaultActiveKey="IMAGE"
            onSelect={(k) => this.setState({ selectedKey: k })}
          >
            <Nav variant="pills" className="d-flex px-2">
              {selectedRow?.image_url ? (
                <Nav.Item className="mr-2 flex-grow-1 text-center">
                  <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Image</Nav.Link>
                </Nav.Item>
              ) : null}
              {selectedRow?.pdf_url ? (
                <Nav.Item className="mr-2 flex-grow-1 text-center">
                  <Nav.Link eventKey={MEDIA_TAB.PDF}>PDF File</Nav.Link>
                </Nav.Item>
              ) : null}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={MEDIA_TAB.IMAGE} className="text-center">
                {!!selectedRow?.image_url &&
                FILE_FORMATS.find((i) => {
                  return selectedRow?.image_url
                    ? selectedRow?.image_url.includes(i)
                    : false;
                }) ? (
                  <Image
                    src={`${storageUrl}${selectedRow?.image_url}`}
                    class="img-fluid"
                    alt={selectedRow?.stone_id}
                  />
                ) : !!selectedRow?.image_url ? (
                  <iframe
                    title="image"
                    src={selectedRow?.image_url}
                    width={"100%"}
                    style={{ height: "60vh" }}
                  />
                ) : (
                  <Image
                    width="100px"
                    className="img-fluid"
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                    alt="Jinagna logo"
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey={MEDIA_TAB.PDF} className="text-center">
                {!!selectedRow?.pdf_url &&
                FILE_FORMATS.find((i) => {
                  return selectedRow?.pdf_url
                    ? selectedRow?.pdf_url.includes(i)
                    : false;
                }) ? (
                  <Image
                    src={`${storageUrl}${selectedRow?.pdf_url}`}
                    class="img-fluid"
                    alt={selectedRow?.stone_id}
                  />
                ) : !!selectedRow?.pdf_url ? (
                  <iframe
                    title="certi"
                    src={
                      !!selectedRow?.pdf_url.includes(".pdf")
                        ? `${storageUrl}${selectedRow?.pdf_url}`
                        : selectedRow?.pdf_url
                    }
                    width={"100%"}
                    style={{ height: "60vh" }}
                  />
                ) : (
                  <Image
                    width="100px"
                    className="img-fluid"
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                    alt="Jinagna logo"
                  />
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </AppModal>
        <AppModal
          show={isOpenDeleteBox}
          title={"Alert!"}
          size="md"
          onHide={() => this.setState({ isOpenDeleteBox: false })}
          footer={
            <Spin loading={deleteLoading}>
              <Button
                variant={`outline-primary`}
                onClick={(e) => this.setState({ isOpenDeleteBox: false })}
                className="ml-1"
              >
                NO
              </Button>
              <Button
                className="ml-1"
                onClick={(e) => this.confirmDeleteAction()}
              >
                YES
              </Button>
            </Spin>
          }
        >
          Are you sure, you want to delete selected products?
        </AppModal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.product.loading,
  productList: state.product.data,
  currentPage: state.product.current_page,
  totalRow: state.product.total,
  perPage: state.product.per_page,
  enquiryLoading: state.product.enquiryLoading,
  enquiryData: state.product.enquiryData,
});
const mapDispatchToProps = {
  fetchFeaturedProduct,
  fetchRecommendedProduct,
  fetchSearchedProduct,
  fetchNewProduct,
  createProductEnquiry,
  createProductPlaceOrder,
  updateNewProduct,
  exportProducts,
  deleteProduct,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminProduct));
